import { yupResolver } from '@hookform/resolvers/yup';
import React, { useMemo } from 'react';
import {
  Controller,
  FormProvider,
  useForm,
  useFormContext,
} from 'react-hook-form';
import * as yup from 'yup';

import { Assessment, AssessmentValidationType } from '@nl-lms/sdk/backend';
import {
  Box,
  Button,
  Checkbox,
  FormField,
  FormWizard,
  Input,
  Modal,
  Separator,
  SideModal,
  SingleSelect,
  Typography,
  useModal,
} from '@nl-lms/ui/components';
import { getMessageFromError } from '@nl-lms/ui/utils';
import { AdminCertificationSelect } from '@nl-lms/web/adminApp/modules/feature-certification/AdminCertificationSelect';

import { useSubmitUpsertEntityFromSideModal } from '../../../../_common/hooks/useSubmitUpsertEntityFromSideModal';
import { ImageUploadInput } from '../../../../_common/modules/S3ImageUploadInput/ImageUploadInput';
import { TextEditorWithFileUpload } from '../../../../_common/modules/TextEditorWithFileUpload';
import { AdminCompetencySelect } from '../../AdminCompetency/AdminCompetencySelect';
import { AdminTagSelect } from '../../AdminTag/AdminTagSelect';
import { AdminAssessmentEditFormQuestionsSection } from './AdminAssessmentEditFormQuestionsSection';
import './AdminAssessmentEditFormSideModal.scss';

type Props = {
  assessment?: Partial<Assessment> & {
    thumbnail: string;
    certificationId: string;
  };
};

const Schema = yup.object().shape({
  name: yup.string().required(),
  description: yup.string().required(),
  thumbnail: yup.string(),
  certificationId: yup.string().nullable(),
  tagIds: yup.array(),
  competencyIds: yup.array(),
  minScore: yup.number().required(),
  duration: yup.number().required(),
  maxAttemptsCount: yup.number().required(),
  validationType: yup.number().required(),
  showResults: yup.boolean(),
  randomizeQuestionOrder: yup.boolean(),
  items: yup
    .array()
    .required()
    .min(1, 'At least one question item is required'),
});

export const AdminAssessmentEditFormSideModal = ({
  assessment = {
    name: '',
    thumbnail: '',
    description: '',
    competencyIds: [],
    tagIds: [],
    // @ts-ignore
    minScore: null,
    // @ts-ignore
    duration: null,
    // @ts-ignore
    maxAttemptsCount: null,
    // @ts-ignore
    validationType: null,
    showResults: false,
    randomizeQuestionOrder: false,
    // @ts-ignore
    certificationId: null,
    items: [],
  },
}: Props) => {
  const formProps = useForm({
    resolver: yupResolver(Schema),
    mode: 'onSubmit',
    defaultValues: {
      ...assessment,
      items: assessment.assessmentFormItems,
      certificationId: assessment?.certificationId || '',
    },
    shouldUnregister: false, // this will be the default in v7 - need to update when that is released
  });
  const {
    handleSubmit,
    formState: { errors },
    control,
    getValues,
  } = formProps;
  const { hide, show } = useModal();
  const { onSubmit, isLoading, error } = useSubmitUpsertEntityFromSideModal({
    createHookName: 'useCreateAssessmentMutation',
    updateHookName: 'useUpdateAssessmentMutation',
    entityId: assessment.id,
  });

  const formSteps = useMemo(() => {
    if (assessment.id) return ['details', 'scoring'];
    return ['details', 'questions', 'scoring'];
  }, [assessment]);

  return (
    <SideModal.Content>
      <FormProvider {...formProps}>
        <FormWizard.Provider withContainer={false} steps={formSteps}>
          <SideModal.Header>
            <SideModal.Title>
              {assessment.id ? 'Edit Assessment' : 'Create New Assessment'}
            </SideModal.Title>
          </SideModal.Header>
          <SideModal.Body>
            <FormWizard.Step name="details">
              <AssessmentEditDetailsSection assessment={assessment} />
            </FormWizard.Step>
            <FormWizard.Step name="questions">
              <Controller
                name="items"
                control={control}
                render={({ field }) => (
                  <AdminAssessmentEditFormQuestionsSection
                    initialItems={
                      getValues('items') ||
                      assessment.items ||
                      assessment.assessmentFormItems
                    }
                    onChange={(items) => field.onChange(items)}
                    errors={errors}
                    getValues={getValues}
                  />
                )}
              />
            </FormWizard.Step>
            <FormWizard.Step name="scoring">
              <AssessmentEditScoringSection />
            </FormWizard.Step>
          </SideModal.Body>
          <SideModal.Actions>
            <SideModal.Error>{getMessageFromError(error)}</SideModal.Error>
            <FormWizard.Navigation />
            <Button
              label={assessment.id ? 'Save' : 'Create'}
              onClick={handleSubmit(onSubmit)}
              isLoading={isLoading}
            />
          </SideModal.Actions>
        </FormWizard.Provider>
      </FormProvider>
    </SideModal.Content>
  );
};

const AssessmentEditDetailsSection = ({ assessment }) => {
  const {
    register,
    getValues,
    formState: { errors },
    control,
  } = useFormContext();

  return (
    <>
      <Box>
        <Box>
          <FormField label="Name" required errorMessage={errors?.name?.message}>
            <Input
              required
              {...register('name')}
              hasError={!!errors?.name?.message}
            />
          </FormField>
        </Box>
      </Box>
      <Box>
        <Box>
          <FormField
            label="Description"
            required
            errorMessage={errors?.description?.message}
          >
            <Controller
              name="description"
              control={control}
              render={({ field }) => (
                <TextEditorWithFileUpload
                  {...field}
                  initialValue={
                    assessment.description || getValues('description')
                  }
                />
              )}
            />
          </FormField>
        </Box>
      </Box>
      <Box>
        <Box>
          <FormField
            label="Thumbnail"
            errorMessage={errors?.thumbnail?.message}
          >
            <Controller
              name="thumbnail"
              control={control}
              render={({ field }) => (
                <ImageUploadInput
                  {...field}
                  initialS3Url={assessment.thumbnail || getValues('thumbnail')}
                />
              )}
            />
          </FormField>
        </Box>
      </Box>
      <Box>
        <Box>
          <FormField label="Competencies">
            <Controller
              name="competencyIds"
              control={control}
              render={({ field }) => (
                <AdminCompetencySelect
                  onChange={field.onChange}
                  selectedItems={field.value}
                />
              )}
            />
          </FormField>
        </Box>
      </Box>
      <Box>
        <Box>
          <FormField label="Tags">
            <Controller
              name="tagIds"
              control={control}
              render={({ field }) => (
                <AdminTagSelect
                  onChange={field.onChange}
                  selectedItems={field.value}
                />
              )}
            />
          </FormField>
        </Box>
      </Box>
      <Box>
        <Box>
          <FormField
            label="Certification"
            errorMessage={errors?.certificationId?.message}
          >
            <Controller
              name="certificationId"
              control={control}
              render={({ field }) => (
                <AdminCertificationSelect
                  {...field}
                  initialSelectedItem={field.value}
                  isClearable
                  hasError={!!errors?.certificationId?.message}
                />
              )}
            />
          </FormField>
        </Box>
      </Box>
    </>
  );
};

const AssessmentEditScoringSection = () => {
  const {
    register,
    watch,
    formState: { errors },
    control,
  } = useFormContext();
  const items = watch('items');
  const maxScore =
    items?.reduce(
      (acc, item) =>
        acc + (item.assessmentQuestionId ? 1 : item.questionCount || 0),
      0
    ) || 0;

  return (
    <>
      <Box margin={{ bottom: 'm' }}>
        <Typography.h2>Scoring</Typography.h2>
      </Box>
      <Box>
        <Box>
          <FormField
            label="Passing Score"
            errorMessage={errors?.minScore?.message}
            required
          >
            <Input {...register('minScore')} type="number" min="0" />
          </FormField>
        </Box>
        <Box>
          <FormField label="Max Score">
            <Input
              name="maxScore"
              disabled
              value={maxScore}
              type="number"
              min="0"
            />
          </FormField>
        </Box>
      </Box>
      <Box>
        <Box>
          <FormField
            label="Maximum Attempts"
            errorMessage={errors?.maxAttemptsCount?.message}
            required
          >
            <Input {...register('maxAttemptsCount')} type="number" min="1" />
          </FormField>
        </Box>
        <Box>
          <FormField
            label="Duration (in minutes)"
            errorMessage={errors?.duration?.message}
            required
          >
            <Input {...register('duration')} type="number" min="1" />
          </FormField>
        </Box>
      </Box>
      <Box>
        <Box>
          <FormField
            label="Validation Type"
            required
            errorMessage={errors?.validationType?.message}
          >
            <Controller
              name="validationType"
              control={control}
              render={({ field }) => (
                <SingleSelect
                  {...field}
                  initialSelectedItem={field.value}
                  options={[
                    {
                      value: AssessmentValidationType.BestResult,
                      label: 'Best Result ',
                    },
                    {
                      value: AssessmentValidationType.LastResult,
                      label: 'Last Result ',
                    },
                  ]}
                />
              )}
            />
          </FormField>
        </Box>
      </Box>
      <Box>
        <Box>
          <FormField errorMessage={errors?.showResults?.message}>
            <Checkbox label="Show Results" {...register('showResults')} />
          </FormField>
        </Box>
        <Box>
          <FormField errorMessage={errors?.randomizeQuestionOrder?.message}>
            <Checkbox
              label="Randomize Question Order"
              {...register('randomizeQuestionOrder')}
            />
          </FormField>
        </Box>
      </Box>
    </>
  );
};

import posthog, { PostHog } from 'posthog-js';
import 'posthog-js/dist/exception-autocapture';
import 'posthog-js/dist/recorder';
import 'posthog-js/dist/surveys';
import 'posthog-js/dist/tracing-headers';
import 'posthog-js/dist/web-vitals';

import { C } from '@nl-lms/ui/constants';

import { TrackingComponent, TrackingEvent, TrackingPayload } from './types';

const NOT_PERMITTED_SEARCH_PARAMS = ['code', 'token', 'tk'];

export class Tracker {
  public instance: PostHog;
  public getUser: () => { id: string; learnerId: string };

  protected enabled = true;

  constructor({ key, name, getUser }) {
    if (!this.enabled) return;

    this.getUser = getUser;

    // if (window.location.host.includes('localhost')) return;

    const instance = posthog.init(key, {
      name: name,
      api_host: `${C.ENV.API_BASE}/e/collect`,
      ui_host: 'https://eu.posthog.com',
      autocapture: false,
      cross_subdomain_cookie: true,
      persistence: 'localStorage',
      capture_pageview: false,
      mask_all_element_attributes: true,
      disable_external_dependency_loading: true,
      disable_session_recording: true,
      store_google: false, // no idea
      ip: false,
      session_recording: {
        maskAllInputs: false,
        maskInputOptions: {
          password: true,
          email: true,
          tel: true,
        },
      },
      sanitize_properties: (props) => {
        let current_url = props.$current_url;
        let referrer_url = props.$referrer;

        if (referrer_url) {
          try {
            const referrer_parts = new URL(props.$referrer);
            NOT_PERMITTED_SEARCH_PARAMS.forEach((param) => {
              referrer_parts.searchParams.delete(param);
            });
            referrer_url = referrer_parts.toString();
          } catch (e) {}
        }

        if (current_url) {
          try {
            const current_url_parts = new URL(props.$current_url);
            NOT_PERMITTED_SEARCH_PARAMS.forEach((param) => {
              current_url_parts.searchParams.delete(param);
            });
            current_url = current_url_parts.toString();
          } catch (e) {}
        }

        return {
          ...props,
          $current_url: current_url,
          $referrer: referrer_url,
          $ip: null,
        };
      },
    });

    if (!instance) return;

    this.instance = instance;
  }

  get user() {
    return this.getUser();
  }

  load() {
    if (!this.user) return;
    if (!this.instance) return;

    const isSameGroup =
      this.instance.getGroups()['customer'] === this.defaultProps().customerId;
    const isSameUser = this.user.id === this.instance.get_distinct_id();

    if (!isSameGroup) {
      this.instance.group('customer', this.defaultProps().customerId, {
        name: this.defaultProps().customerName,
      });
    }

    if (!isSameUser) {
      this.instance.identify(this.user.id, undefined, {
        userId: this.user.id,
        learnerId: this.user.learnerId,
        ...this.defaultProps(),
      });
    }
  }

  unload() {
    if (!this.instance) return;

    this.instance.reset();
  }

  startRecording() {
    this.instance.startSessionRecording();
  }

  stopRecording() {
    this.instance.stopSessionRecording();
  }

  event(
    event: TrackingEvent,
    component: TrackingComponent,
    payload?: TrackingPayload
  ) {
    if (!this.instance) return;
    this.instance.capture(event, {
      component,
      ...(payload || {}),
      ...this.defaultProps(),
    });
  }

  pageView() {
    if (!this.instance) return;

    this.instance.capture('$pageview', this.defaultProps());
  }

  pageLeave(props = {}) {
    if (!this.instance) return;

    this.instance.capture('$pageleave', { ...props, ...this.defaultProps() });
  }

  private defaultProps() {
    return {
      customerId: C.CUSTOMER_ID,
      customerName: C.NAME,
    };
  }
}

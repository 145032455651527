import { yupResolver } from '@hookform/resolvers/yup';
import { isObject } from 'lodash';
import { useCallback, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { LiveCourse, LiveSession } from '@nl-lms/sdk/backend';
import {
  Box,
  Button,
  Checkbox,
  DateTime,
  FormField,
  Icon,
  Input,
  Sensitive,
  Separator,
  SideModal,
  Tooltip,
  Typography,
  useConfirmationModal,
  useModal,
} from '@nl-lms/ui/components';
import { C } from '@nl-lms/ui/constants';
import { useRoutingAction } from '@nl-lms/ui/hooks';
import { useTracker } from '@nl-lms/ui/modules';
import { getMessageFromError } from '@nl-lms/ui/utils';
import { _, dateFns } from '@nl-lms/vendor';
import { useAction } from '@nl-lms/web/_common/hooks/useAction';
import { routes } from '@nl-lms/web/lib/routes';

import { Can } from '../../../Can';
import { useShowEntityEditSideModalForm } from '../../../_common/hooks';
import { ImageUploadInput } from '../../../_common/modules/S3ImageUploadInput/ImageUploadInput';
import { TextEditorWithFileUpload } from '../../../_common/modules/TextEditorWithFileUpload';
import { adminApi, useApi } from '../../../_common/services/api';
import { getCleanDate } from '../../../_common/utils';
import { AdminDailyScheduleTemplateInput } from '../../_common/AdminDailyScheduleTemplateInput/AdminDailyScheduleTemplateInput';
import { LocationSelect } from '../../_common/LocationSelect';
import { AdminLearnerGroupMultiSelect } from '../AdminLearner/AdminLearnerGroupSelect';
import { AdminLearnerMultiSelect } from '../AdminLearner/AdminLearnerSelect';
import { AdminLiveCourseLanguageSelect } from '../AdminLiveCourse/AdminLiveCourseLanguageSelect';
import AdminLiveCourseLearningTypeSelect from '../AdminLiveCourse/AdminLiveCourseLearningTypeSelect';
import { AdminLiveCourseSingleSelect } from '../AdminLiveCourse/AdminLiveCourseSelect';
import {
  AdminSurveyMultiSelect,
  AdminSurveySingleSelect,
} from '../AdminSurvey/AdminSurveySelect';
import { AdminTagSelect } from '../AdminTag/AdminTagSelect';
import { AdminVendorSelect } from '../AdminVendor/AdminVendorSelect';
import { AdminVenueFeaturesSelect } from '../AdminVenue/AdminVenueFeaturesSelect';
import { AdminLiveSessionApprovalTypeSelect } from './AdminLiveSessionApprovalTypeSelect';
import { AdminLiveSessionVirtualLocationTypeSelect } from './AdminLiveSessionVirtualLocationTypeSelect';
import {
  isVirtualSession,
  willCalendarEventBeUpdated,
} from './adminLiveSessionUtils';

const { useUpdateLiveSessionMutation, useCreateLiveSessionMutation } = adminApi;
type LiveSessionUpsertModalProps = {
  liveSession?: Partial<LiveSession>;
};

export type LiveSessionUpsertModalReturnType = {
  success: boolean;
  liveSessionId: string;
};

export const LiveSessionSchema = yup.object().shape({
  courseId: yup.string().required(),
  name: yup.string().required(),
  language: yup.string().required(),
  prework: yup.string(),
  startDate: yup.string(),
  description: yup.string(),
  thumbnail: yup.string(),
  minPax: yup.number().required(),
  maxPax: yup.number().required(),
  feedbackFormIds: yup.array(),
  registrationFormId: yup.string().optional(),
  scheduleTemplate: yup
    .array(
      yup.object({
        startDate: yup.date().nullable(),
        endDate: yup.date().when('startDate', ([startDate], schema) => {
          if (!startDate) return schema.nullable();

          const maxEndDate = dateFns.endOfDay(startDate);
          return schema
            .min(startDate)
            .max(
              maxEndDate,
              `End date must not be greater than ${maxEndDate.toLocaleString()}`
            )
            .nullable();
        }),
        schedule: yup
          .array(
            yup.object({
              type: yup
                .number()
                .oneOf([
                  C.I_SCHEDULE_BLOCK.CONTENT_DELIVERY,
                  C.I_SCHEDULE_BLOCK.BREAK,
                ])
                .required(),
              duration: yup
                .number()
                .max(60 * 24)
                .required(),
            })
          )
          .min(1),
      })
    )
    .required()
    .min(1),
  requirements: yup.array(),
  approvalType: yup.mixed().oneOf(Object.values(C.I_APPROVAL_TYPES)).required(),
  approvers: yup.array(),
  tagIds: yup.array(),
  targetLearnerGroupIds: yup.array(),
  type: yup.mixed().oneOf(Object.values(C.I_LEARNING_TYPES)).required(),
  location: yup.object().nullable(),
  virtualLocation: yup.string().nullable(),
  virtualLocationType: yup
    .mixed()
    .oneOf(Object.values(C.I_VIRTUAL_LOCATION_TYPES))
    .nullable(),
  calendarEnabled: yup.boolean(),
  anonymizeFeedbackResponses: yup.boolean(),
  enableWaitingList: yup.boolean(),
  vendorId: yup.string().nullable(),
});

const parseEntity = (liveSession) => {
  let location = { city: null, country: null, region: null };
  let virtualLocation = null;
  if (
    liveSession.type == C.I_LEARNING_TYPES.IN_CLASS ||
    liveSession.type == C.I_LEARNING_TYPES.CONFERENCE
  ) {
    location = liveSession.location || {
      city: null,
      country: null,
      region: null,
    };
    virtualLocation = null;
  } else {
    virtualLocation = liveSession.virtualLocation;
    location = { city: null, country: null, region: null };
  }

  const surveys = [];
  if (liveSession.feedbackFormIds) {
    surveys.push(
      // @ts-ignore
      ...liveSession.feedbackFormIds.map((id) => ({
        surveyId: id,
        type: C.I_SURVEY_TYPES.FEEDBACK,
      }))
    );
  }
  if (liveSession.registrationFormId) {
    // @ts-ignore
    surveys.push({
      // @ts-ignore
      surveyId: liveSession.registrationFormId,
      type: C.I_SURVEY_TYPES.REGISTRATION,
    });
  }
  liveSession.scheduleTemplate = liveSession.scheduleTemplate.map((s) => ({
    ...s,
    startDate: s.startDate instanceof Date ? s.startDate.toISOString() : null,
    endDate: s.endDate instanceof Date ? s.endDate.toISOString() : null,
  }));

  return {
    ...liveSession,
    ...location,
    virtualLocation,
    location,
    surveys,
    approverIds: liveSession.approvers.map((a) => a.value || a.id),
  };
};

const { useLazyListLearnersByIdsQuery } = adminApi;

export const AdminLiveSessionEditFormSideModal = ({
  liveSession = {},
}: LiveSessionUpsertModalProps) => {
  const api = useApi();

  const [
    updateLiveSession,
    { isLoading: isUpdateLoading, error: updateError },
  ] = useUpdateLiveSessionMutation();
  const [
    createLiveSession,
    { isLoading: isCreateLoading, error: createError },
  ] = useCreateLiveSessionMutation();

  const {
    handleSubmit,
    register,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(LiveSessionSchema),
    mode: 'onSubmit',
    defaultValues: {
      ..._.omit(liveSession, 'id'),
      virtualLocation: !liveSession.id ? 'TBA' : liveSession.virtualLocation,
      approvers: liveSession.approvers || [],
      scheduleTemplate: (liveSession.scheduleTemplate || []).map((v) => {
        const startDate = getCleanDate(v.startDate);
        const endDate = getCleanDate(v.endDate);

        return { schedule: v.schedule, startDate, endDate };
      }),
      location: liveSession.city
        ? {
            city: liveSession.city,
            region: liveSession.region,
            country: liveSession.country,
          }
        : null,
    },
  });

  const goTo = useRoutingAction();
  const { hide } = useModal<LiveSessionUpsertModalReturnType>();
  const error = createError || updateError;
  const isLoading = isUpdateLoading || isCreateLoading;
  const mutate = liveSession.id ? updateLiveSession : createLiveSession;
  const track = useTracker();
  // Problem: When you click one of the buttons both of them go in a loading
  // state
  // This is a temp solution since we are using the same mutation and flags on
  // two differrent buttons
  const [isCreatingAndNavigating, setIsCreatingAndNavigating] = useState(false);
  const onSubmit = useCallback(
    async (entity, goToLiveSessionView = false) => {
      track.event('button_clicked', 'create-live-session', { type: 'b' });
      const parsedEntity = parseEntity(entity);
      if (goToLiveSessionView) setIsCreatingAndNavigating(true);
      if (
        liveSession?.id &&
        willCalendarEventBeUpdated(liveSession, parsedEntity)
      ) {
        const confirmation = await showConfirmationModal();
        if (!confirmation.confirmed) return;
      }
      if (liveSession.id) parsedEntity.id = liveSession.id;
      const result = await mutate(parsedEntity);
      setIsCreatingAndNavigating(false);
      if ('data' in result) {
        const baseId = liveSession.id || '';
        const sessionId =
          isObject(result.data) && 'id' in result.data
            ? result.data.id
            : baseId;

        if (goToLiveSessionView)
          goTo(routes.admin.manage.liveSessions.item.path.full(sessionId));
        hide({ success: true, liveSessionId: sessionId });
      }
    },
    [liveSession, mutate]
  );

  const [listLearnersByIds] = useLazyListLearnersByIdsQuery();
  const listLearnersByIdsAction = useAction(listLearnersByIds);

  const onChangeCourse = useCallback(
    async (item) => {
      if (!item?.course) return;

      const course = (await api.course.get(item.course.id)) as any;
      let approvers = [];
      if (course.trainingSessionApproverIds.length) {
        const fetchResult = await listLearnersByIdsAction(
          course.trainingSessionApproverIds
        );

        approvers =
          fetchResult?.data?.rows?.map((l) => ({
            value: l.id,
            label: `${l.firstName} ${l.lastName}`,
          })) || [];
      }

      const sessionPropsFromCourse =
        getLiveSessionPropertiesFromACourse(course);
      Object.keys(sessionPropsFromCourse).forEach((propName: any) => {
        setValue(propName, sessionPropsFromCourse[propName]);
      });

      setValue('description', course.description || '');
      setValue('prework', course.prework || '');
      setValue('approvers', approvers);
      setValue('tagIds', course.tagIds);
      setValue('targetLearnerGroupIds', course.targetLearnerGroupIds);
      setValue('vendorId', course.vendorId);
    },
    [setValue, control]
  );

  const courseId = watch('courseId');
  const showConfirmationModal = useConfirmationModal({
    message:
      'Your changes will trigger an update on the calendar event. Do you want to proceed with this action?',
  });

  return (
    <SideModal.Content>
      <SideModal.Header>
        <SideModal.Title>
          {liveSession.id ? 'Edit Live Session' : 'Create New Live Session'}
        </SideModal.Title>
      </SideModal.Header>
      <SideModal.Body>
        <Box margin={{ bottom: 'm' }}>
          <Typography.h2>General</Typography.h2>
        </Box>
        <Box>
          <FormField
            label="Course"
            required
            errorMessage={errors?.courseId?.message}
          >
            <Controller
              name="courseId"
              control={control}
              render={({ field }) => (
                <AdminLiveCourseSingleSelect
                  {...field}
                  onChange={onChangeCourse}
                  initialSelectedItem={{
                    label: liveSession.course?.name,
                    value: liveSession.courseId,
                  }}
                  hasError={!!errors?.courseId?.message}
                />
              )}
            />
          </FormField>
        </Box>
        <Box>
          <FormField
            label="Session Name"
            required
            errorMessage={errors?.name?.message}
          >
            <Input
              required
              {...register('name')}
              hasError={!!errors?.name?.message}
            />
          </FormField>
        </Box>
        <Box>
          <Box style={{ minWidth: '365px' }}>
            <FormField
              label="Start Date"
              errorMessage={errors?.startDate?.message}
            >
              <Controller
                name="startDate"
                control={control}
                render={({ field }) => (
                  <DateTime
                    date={field.value}
                    onChange={(e) => field.onChange(e.target.value)}
                  />
                )}
              />
            </FormField>
          </Box>
          <Box>
            <FormField
              label="Type"
              required
              errorMessage={errors?.type?.message}
            >
              <Controller
                name="type"
                control={control}
                render={({ field }) => (
                  <AdminLiveCourseLearningTypeSelect
                    {...field}
                    // @ts-ignore
                    initialValue={liveSession.type}
                    hasError={!!errors?.type?.message}
                  />
                )}
              />
            </FormField>
          </Box>
        </Box>
        <Box>
          <Box style={{ minWidth: '365px' }}>
            {isVirtualSession(watch('type')) ? (
              <Box>
                {(!!C.FEATURES.msTeamsIntegrationEnabled ||
                  !!C.FEATURES.gmeetIntegrationEnabled) && (
                  <Box>
                    <FormField
                      label="Virtual Location Type"
                      errorMessage={errors?.virtualLocationType?.message}
                    >
                      <Controller
                        name="virtualLocationType"
                        control={control}
                        render={({ field }) => (
                          // @ts-ignore
                          <AdminLiveSessionVirtualLocationTypeSelect
                            {...field}
                            hasError={!!errors?.virtualLocationType?.message}
                          />
                        )}
                      />
                    </FormField>
                  </Box>
                )}
                <Box>
                  <FormField label="Virtual Location">
                    <Sensitive>
                      <Input
                        {...register('virtualLocation')}
                        disabled={!!watch('virtualLocationType')}
                      />
                    </Sensitive>
                  </FormField>
                </Box>
              </Box>
            ) : (
              <FormField label="Physical Location">
                <Controller
                  name="location"
                  control={control}
                  render={({ field }) => (
                    <LocationSelect
                      {...field}
                      // @ts-ignore
                      initialLocation={getInitialLocation(liveSession)}
                    />
                  )}
                />
              </FormField>
            )}
          </Box>
          <Box>
            <FormField
              label="Language"
              required
              errorMessage={errors?.language?.message}
            >
              <Controller
                name="language"
                control={control}
                render={({ field }) => (
                  <AdminLiveCourseLanguageSelect
                    {...field}
                    // @ts-ignore
                    value={field.value}
                    hasError={!!errors?.language?.message}
                  />
                )}
              />
            </FormField>
          </Box>
        </Box>
        <Box>
          <Box>
            <FormField
              label="Description"
              errorMessage={errors?.description?.message}
              helpText="This field will also be used as the description field for the calendar event (if the calendar integration option is enabled). Keep in mind that images will not be visible in the calendar event. "
            >
              <Controller
                name="description"
                control={control}
                render={({ field }) => (
                  <TextEditorWithFileUpload
                    controlKey={`courseId-${watch('courseId')}`}
                    {...field}
                  />
                )}
              />
            </FormField>
          </Box>
        </Box>
        <Box>
          <Box>
            <FormField
              label="Agenda"
              errorMessage={errors?.description?.message}
            >
              <Controller
                name="prework"
                control={control}
                render={({ field }) => (
                  <TextEditorWithFileUpload
                    controlKey={`courseId-${watch('courseId')}`}
                    {...field}
                  />
                )}
              />
            </FormField>
          </Box>
        </Box>
        <Separator />
        <Box margin={{ bottom: 'm' }}>
          <Typography.h2>Registration</Typography.h2>
        </Box>
        <Box>
          <FormField
            label="Registration Form"
            errorMessage={errors?.registrationFormId?.message}
          >
            <Controller
              name="registrationFormId"
              control={control}
              render={({ field }) => (
                <AdminSurveySingleSelect
                  {...field}
                  key={courseId}
                  initialSelectedItem={
                    field.value || liveSession?.registrationFormId
                  }
                  isClearable
                  hasError={!!errors?.registrationFormId?.message}
                />
              )}
            />
          </FormField>
        </Box>
        <Box>
          <Box>
            <FormField
              label="Approval Type"
              required
              errorMessage={errors?.approvalType?.message}
            >
              <Controller
                name="approvalType"
                control={control}
                render={({ field }) => (
                  // @ts-ignore
                  <AdminLiveSessionApprovalTypeSelect
                    {...field}
                    hasError={!!errors?.approvalType?.message}
                  />
                )}
              />
            </FormField>
          </Box>
          <Box>
            <FormField label="Approvers" required>
              <Controller
                name="approvers"
                control={control}
                render={({ field }) => (
                  <AdminLearnerMultiSelect
                    {...field}
                    initialSelectedItems={field.value?.map((l) => ({
                      value: l.id,
                      label: `${l.firstName} - ${l.lastName}`,
                    }))}
                    disabled={
                      watch('approvalType') !=
                      C.I_APPROVAL_TYPES.TRAINING_SESSION_HIERARCHIC_MANAGER
                    }
                  />
                )}
              />
            </FormField>
          </Box>
        </Box>
        <Separator />
        <Box margin={{ bottom: 'm' }}>
          <Typography.h2>Details</Typography.h2>
        </Box>
        <Box>
          <Box>
            <FormField label="Vendor">
              <Controller
                name="vendorId"
                control={control}
                render={({ field }) => (
                  <AdminVendorSelect
                    {...field}
                    initialSelectedItem={{
                      value: liveSession?.vendorId,
                      // @ts-ignore
                      label: liveSession?.vendorName,
                    }}
                    isClearable
                    hasError={!!errors?.vendorId?.message}
                  />
                )}
              />
            </FormField>
          </Box>
        </Box>
        <Box flex={{ flexDirection: 'row', gap: 'm' }}>
          <Box flex={{ flex: 1 }}>
            <FormField
              label="Min Pax"
              required
              errorMessage={errors?.minPax?.message}
            >
              <Input
                required
                {...register('minPax')}
                type="number"
                hasError={!!errors?.minPax?.message}
              />
            </FormField>
          </Box>
          <Box flex={{ flex: 1 }}>
            <FormField
              label="Max Pax"
              required
              errorMessage={errors?.maxPax?.message}
            >
              <Input
                required
                {...register('maxPax')}
                type="number"
                hasError={!!errors?.maxPax?.message}
              />
            </FormField>
          </Box>
        </Box>
        <Box>
          <FormField label="Tags">
            <Controller
              name="tagIds"
              control={control}
              render={({ field }) => (
                <AdminTagSelect {...field} selectedItems={field.value} />
              )}
            />
          </FormField>
        </Box>
        <Box>
          <FormField label="Learner Visibility">
            <Controller
              name="targetLearnerGroupIds"
              control={control}
              render={({ field }) => (
                <AdminLearnerGroupMultiSelect
                  selectedItems={field.value}
                  {...field}
                />
              )}
            />
          </FormField>
        </Box>
        <Box>
          <FormField
            label="Requirements"
            // errorMessage={errors?.requirements?.message}
          >
            <Controller
              name="requirements"
              control={control}
              render={({ field }) => (
                <AdminVenueFeaturesSelect
                  {...field}
                  // @ts-ignore
                  initialValue={liveSession.requirements}
                />
              )}
            />
          </FormField>
        </Box>
        <Box>
          <FormField label="Feedback Forms">
            <Controller
              name="feedbackFormIds"
              control={control}
              render={({ field }) => (
                <AdminSurveyMultiSelect
                  {...field}
                  key={courseId}
                  selectedItems={field.value}
                  initialSelectedItems={field.value}
                />
              )}
            />
          </FormField>
        </Box>
        <Box
          flex={{ flexDirection: 'row', alignItems: 'flex-start', gap: 'm' }}
        >
          <Box>
            <FormField>
              <Checkbox
                {...register('enableWaitingList')}
                label="Enable Waiting List"
              />
            </FormField>
          </Box>

          {liveSession?.id ? null : (
            <Box>
              <FormField>
                <Checkbox
                  {...register('anonymizeFeedbackResponses')}
                  label="Anonymize Feedback Responses"
                />
              </FormField>
            </Box>
          )}

          <Box>
            <FormField>
              <Checkbox
                {...register('calendarEnabled')}
                label="Enable Calendar"
              />
            </FormField>
          </Box>
        </Box>
        <Box>
          <Box>
            <FormField
              label="Thumbnail"
              errorMessage={errors?.thumbnail?.message}
            >
              <Controller
                name="thumbnail"
                control={control}
                render={({ field }) => (
                  <ImageUploadInput
                    {...field}
                    initialS3Url={liveSession.thumbnail}
                    s3Url={field.value}
                  />
                )}
              />
            </FormField>
          </Box>
        </Box>
        <Separator />
        <Box margin={{ bottom: 'm' }}>
          <Typography.h2>Schedule</Typography.h2>
        </Box>
        <Box>
          <Box>
            <FormField
              label="Schedule"
              required
              errorMessage={
                errors?.scheduleTemplate ? 'Some errors found' : null
              }
            >
              <Controller
                name="scheduleTemplate"
                control={control}
                render={({ field }) => {
                  return (
                    <AdminDailyScheduleTemplateInput
                      {...field}
                      errors={errors?.scheduleTemplate as any}
                      // @ts-ignore
                      startDate={getCleanDate(watch('startDate'))}
                      withDates
                    />
                  );
                }}
              />
            </FormField>
          </Box>
        </Box>
      </SideModal.Body>
      <SideModal.Actions>
        <SideModal.Error>{getMessageFromError(error)}</SideModal.Error>

        {!liveSession?.id ? (
          <Button
            label={'Create And Go To Session View'}
            regular
            disabled={isLoading && !isCreatingAndNavigating}
            onClick={handleSubmit((entity) => onSubmit(entity, true))}
            isLoading={isLoading && isCreatingAndNavigating}
          />
        ) : null}
        <Button
          label={liveSession.id ? 'Save' : 'Create'}
          disabled={isLoading && isCreatingAndNavigating}
          onClick={handleSubmit((entity) => onSubmit(entity))}
          isLoading={isLoading && !isCreatingAndNavigating}
        />
      </SideModal.Actions>
    </SideModal.Content>
  );
};

const getInitialLocation = (liveSession: Partial<LiveSession>) => {
  if (liveSession.city) {
    return {
      country: liveSession.country,
      city: liveSession.city,
      region: liveSession.region,
    };
  }
};

export const AdminLiveSessionEditButton = ({ liveSessionId }) => {
  const api = useApi();
  const onClickEdit = useShowEntityEditSideModalForm({
    ModalComponent: AdminLiveSessionEditFormSideModal,
    entityName: 'liveSession',
    buildInitialEntity: async () => {
      const data = (await api.session.get(liveSessionId)) as any;
      data.registrationFormId = data.surveys.find(
        (s) => s.type === C.I_SURVEY_TYPES.REGISTRATION
      )?.surveyId;

      data.feedbackFormIds = data.surveys
        .filter((s) => s.type === C.I_SURVEY_TYPES.FEEDBACK)
        .map((s) => s.surveyId);

      return data;
    },
  });

  return (
    <Can do="edit" on="live_session">
      <div
        className="admin-live-session-view-page__header-action"
        onClick={onClickEdit}
      >
        <Tooltip.top title="Edit">
          <Icon.EditIcon />
        </Tooltip.top>
      </div>
    </Can>
  );
};

export const getLiveSessionPropertiesFromACourse = (
  course: Partial<LiveCourse>
): Partial<LiveSession> => ({
  courseId: course.id,
  name: course.name,
  language: course.language,
  // prework: course.prework,
  // description: course.description,
  thumbnail: course.thumbnail,
  minPax: course.minPax,
  type: course.type,
  maxPax: course.maxPax,
  scheduleTemplate: course.scheduleTemplate,
  requirements: course.requirements,
  approvalType: course.trainingSessionApprovalType,
  approverIds: course.trainingSessionApproverIds,
  // @ts-ignore
  feedbackFormIds: course.surveys
    .filter((s) => s.type === C.I_SURVEY_TYPES.FEEDBACK)
    .map((s) => s.surveyId),
  registrationFormId: (course?.surveys || []).find(
    (s) => s.type === C.I_SURVEY_TYPES.REGISTRATION
  )?.surveyId,
});

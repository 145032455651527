import * as z from 'zod';

import { ContentLibraryItem, ContentLibraryItemType } from '../sdk';

export const ContentLibraryItemSchema: z.ZodType<ContentLibraryItem> = z.object(
  {
    id: z.string(),
    name: z.string(),
    description: z.string().nullable(),
    thumbnail: z.string().nullable(),
    tagIds: z.array(z.string()),
    competencyIds: z.array(z.string()),
    url: z.string(),
    type: z.nativeEnum(ContentLibraryItemType),
    createdAt: z.date(),
    updatedAt: z.date(),
  }
);

export const CreateContentLibraryItemSchema: z.ZodType<
  Omit<ContentLibraryItem, 'id' | 'createdAt' | 'updatedAt'>
> = z.object({
  name: z.string(),
  url: z.string(),
  description: z.string().nullable(),
  thumbnail: z.string().nullable(),
  competencyIds: z.array(z.string()),
  tagIds: z.array(z.string()),
  type: z.nativeEnum(ContentLibraryItemType),
});
export const UpdateContentLibraryItemSchema: z.ZodType<
  Partial<Omit<ContentLibraryItem, 'id' | 'createdAt' | 'updatedAt'>>
> = z.object({
  name: z.string(),
  url: z.string(),
  description: z.string().nullable(),
  thumbnail: z.string().nullable(),
  competencyIds: z.array(z.string()),
  tagIds: z.array(z.string()),
  type: z.nativeEnum(ContentLibraryItemType),
});

export const ContentLibraryItemIdParamSchema = z.object({
  contentLibraryItemId: z.string(),
});

import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import {
  ThemeProvider,
  useActive,
  useChainedCommands,
  useCurrentSelection,
  useHelpers,
  useRemirrorContext,
} from '@remirror/react';
import React, { useCallback, useState } from 'react';
import 'remirror/styles/all.css';

import { _ } from '@nl-lms/vendor';

import { useTestProps } from '../../hooks';
import { Icon } from '../index';
import { TidComponent } from '../index.types';
import './TextEditor.scss';
import { TextEditorProvider } from './TextEditorContext';
import {
  TextEditorInsertFilesButton,
  TextEditorInsertImagesButton,
} from './TextEditorFileUpload';
import {
  TextEditorEditLinkButton,
  TextEditorRemoveLinkButton,
} from './TextEditorLinkActions';
import { TextEditorMentionsMenu } from './TextEditorMentionsMenu';

type TextEditorProps = TidComponent<{
  initialValue?: string;
  // Use value ATM to make it easier to switch editors
  // This doesn't mean that this input can be controlled
  value?: string | null;
  placeholder?: string;
  onChange: (html: string, markdownValue: string) => void;
  onUploadFile?: (file: File) => Promise<string>;
  controlKey?: string;
  mentionOptions?: { id: string; label: string }[];
}>;

/**
 * TODO/Known Issues
 * - Selected text clears when another element gets focus (although the actual "selection" object is correct)
 *
 */
export const TextEditor = (props: TextEditorProps) => {
  const commonProps = useTestProps(props);

  return (
    <ThemeProvider className="remirror-theme">
      <div className="texteditor" {...commonProps}>
        <TextEditorProvider {...props}>
          <EditorToolbar>
            <EditorToolbarItem>
              <EditorFontSizeMenu />
            </EditorToolbarItem>
            <EditorToolbarItem>
              <EditorTextColorMenu />
            </EditorToolbarItem>
            <EditorToolbarSeparator />
            <EditorToolbarItem>
              <EditorToggleButton title="Toggle Bold" commandName="toggleBold">
                <Icon.BoldIcon />
              </EditorToggleButton>
            </EditorToolbarItem>
            <EditorToolbarItem>
              <EditorToggleButton
                title="Toggle Italic"
                commandName="toggleItalic"
              >
                <Icon.ItalicIcon />
              </EditorToggleButton>
            </EditorToolbarItem>
            <EditorToolbarItem>
              <EditorToggleButton
                title="Toggle Underline"
                commandName="toggleUnderline"
              >
                <Icon.UnderlineIcon />
              </EditorToggleButton>
            </EditorToolbarItem>
            <EditorToolbarItem>
              <EditorToggleButton
                title="Toggle Blockquote"
                commandName="toggleBlockquote"
              >
                <Icon.QuoteIcon />
              </EditorToggleButton>
            </EditorToolbarItem>
            <EditorToolbarSeparator />
            <EditorToolbarItem>
              <EditorToggleButton
                title="Increase Indent"
                commandName="increaseIndent"
              >
                <Icon.IndentRightIcon />
              </EditorToggleButton>
            </EditorToolbarItem>
            <EditorToolbarItem>
              <EditorToggleButton
                title="Decrease Indent"
                commandName="decreaseIndent"
              >
                <Icon.IndentLeftIcon />
              </EditorToggleButton>
            </EditorToolbarItem>
            <EditorToolbarItem>
              <EditorToggleButton title="Align Left" commandName="leftAlign">
                <Icon.AlignLeftIcon />
              </EditorToggleButton>
            </EditorToolbarItem>
            <EditorToolbarItem>
              <EditorToggleButton
                title="Align Center"
                commandName="centerAlign"
              >
                <Icon.AlignCenterIcon />
              </EditorToggleButton>
            </EditorToolbarItem>
            <EditorToolbarItem>
              <EditorToggleButton title="Align Right" commandName="rightAlign">
                <Icon.AlignRightIcon />
              </EditorToggleButton>
            </EditorToolbarItem>
            <EditorToolbarItem>
              <EditorToggleButton title="Justify" commandName="justifyAlign">
                <Icon.JustifyIcon />
              </EditorToggleButton>
            </EditorToolbarItem>
            <EditorToolbarSeparator />
            <EditorToolbarItem>
              <EditorToggleButton
                title="Toggle Bullet List"
                commandName="toggleBulletList"
              >
                <Icon.ListIcon />
              </EditorToggleButton>
            </EditorToolbarItem>
            <EditorToolbarItem>
              <EditorToggleButton
                title="Toggle Ordered List"
                commandName="toggleOrderedList"
              >
                <Icon.OrderedListIcon />
              </EditorToggleButton>
            </EditorToolbarItem>
            <EditorToolbarSeparator />
            <EditorToolbarItem>
              <TextEditorEditLinkButton />
            </EditorToolbarItem>
            <EditorToolbarItem>
              <TextEditorRemoveLinkButton />
            </EditorToolbarItem>
            {props.onUploadFile ? (
              <>
                <EditorToolbarSeparator />
                <EditorToolbarItem>
                  <TextEditorInsertFilesButton />
                </EditorToolbarItem>
                <EditorToolbarItem>
                  <TextEditorInsertImagesButton />
                </EditorToolbarItem>
              </>
            ) : null}
          </EditorToolbar>
          <EditorTextInput />
          <TextEditorMentionsMenu />
        </TextEditorProvider>
      </div>
    </ThemeProvider>
  );
};

const EditorTextInput = ({
  style,
  className,
}: {
  className?: string;
  style?: React.CSSProperties;
}) => {
  return (
    <div
      style={style}
      {..._.omit(
        useRemirrorContext().getRootProps({
          className: `texteditor__text-input ${className}`,
        }),
        'key'
      )}
    />
  );
};

export const Editor = TextEditor;

const EditorToolbar = ({ children }) => {
  return <div className="texteditor__toolbar">{children}</div>;
};

const EditorToolbarSeparator = () => {
  return <div className="texteditor__toolbar-separator" />;
};

const EditorToolbarItem = ({ children }) => {
  return <div className="texteditor__toolbar-item">{children}</div>;
};

interface ToggleButtonProps {
  children: React.ReactNode;
  title: string;
  commandName:
    | 'toggleBold'
    | 'toggleItalic'
    | 'toggleUnderline'
    | 'toggleBulletList'
    | 'toggleBlockquote'
    | 'leftAlign'
    | 'rightAlign'
    | 'centerAlign'
    | 'decreaseIndent'
    | 'removeLink'
    | 'justifyAlign'
    | 'increaseIndent'
    | 'toggleOrderedList';
}

const EditorToggleButton = ({
  children,
  title,
  commandName,
}: ToggleButtonProps) => {
  const chain = useChainedCommands();
  const active = useActive(true);
  const { getCommandOptions } = useHelpers();
  const options = getCommandOptions(commandName);
  const onClick = useCallback(() => {
    const cmd = chain[commandName]();
    cmd.focus().run();
  }, [commandName]);
  // @ts-ignore
  const isActive = active[options.name] ? active[options.name]() : false;
  return (
    <button
      onClick={onClick}
      className={`texteditor__toggle-button ${
        isActive ? 'texteditor__toggle-button--active' : ''
      }`}
      title={title}
    >
      {children}
    </button>
  );
};

const EditorFontSizeMenu = () => {
  const chain = useChainedCommands();
  const helpers = useHelpers(true);
  const selection = useCurrentSelection();
  const [[selectionSize]] = helpers.getFontSizeForSelection(selection);
  const [domSize] = helpers.getFontSizeFromDom(selection);
  const onClickSetFontSize = useCallback((size) => {
    const cmd = chain.setFontSize(size);
    cmd.focus().run();
  }, []);

  const size = selectionSize || domSize;
  const label = size || 'Size';
  const fontSizeOptions = [10, 12, 14, 16, 20, 25, 36, 48, 50];
  return (
    <DropdownMenu.Root>
      <div className="dropdown-menu">
        <DropdownMenu.Trigger>
          <span className="texteditor__floating-menu">{label}</span>
        </DropdownMenu.Trigger>
        <DropdownMenu.Content asChild style={{ zIndex: 2 }}>
          <div className="dropdown-menu__content">
            {fontSizeOptions.map((fontSize) => (
              <DropdownMenu.Item
                key={`font-size-${fontSize}`}
                onSelect={() => onClickSetFontSize(fontSize)}
                asChild
              >
                <div className="dropdown-menu__option">
                  <DropdownMenu.Label>{fontSize}</DropdownMenu.Label>
                </div>
              </DropdownMenu.Item>
            ))}
          </div>
        </DropdownMenu.Content>
      </div>
    </DropdownMenu.Root>
  );
};

const EditorTextColorMenu = () => {
  const chain = useChainedCommands();
  const [textColor, setTextColor] = useState('black');
  const selection = useCurrentSelection();
  const onClickSetTextColor = useCallback(
    (color) => {
      setTextColor(color);
      const cmd = chain.setTextColor(color, { selection });
      cmd.focus().run();
    },
    [selection]
  );

  const colorOptions = [
    'black',
    'darkgrey',
    'grey',
    'lightgrey',
    'white',
    'red',
    'green',
    'yellow',
    'blue',
    'purple',
    'orange',
  ];
  return (
    <DropdownMenu.Root>
      <div className="text-editor-dropdown-menu">
        <DropdownMenu.Trigger>
          <span className="texteditor__text-color" style={{ color: textColor }}>
            A
          </span>
        </DropdownMenu.Trigger>
        <DropdownMenu.Content asChild style={{ zIndex: 2 }}>
          <div className="text-editor-dropdown-menu__content">
            {colorOptions.map((colorOption) => (
              <DropdownMenu.Item
                key={`font-size-${colorOption}`}
                onSelect={() => onClickSetTextColor(colorOption)}
                asChild
              >
                <div className="text-editor-dropdown-menu__option">
                  <EditorTextColorMenuItem
                    color={colorOption}
                    label={colorOption}
                  />
                </div>
              </DropdownMenu.Item>
            ))}
          </div>
        </DropdownMenu.Content>
      </div>
    </DropdownMenu.Root>
  );
};

const EditorTextColorMenuItem = ({ color, label }) => (
  <div className="texteditor__color-menu-item">
    <span
      className="texteditor__color-menu-item-box"
      style={{ backgroundColor: color }}
    />
    <span>{label}</span>
  </div>
);

TextEditor.Input = EditorTextInput;
TextEditor.Provider = TextEditorProvider;
TextEditor.FontSizeMenu = EditorFontSizeMenu;
TextEditor.TextColorMenu = EditorTextColorMenu;
TextEditor.ToggleButton = EditorToggleButton;
TextEditor.EditLinkButton = TextEditorEditLinkButton;
TextEditor.InsertFilesButton = TextEditorInsertFilesButton;
TextEditor.InsertImagesButton = TextEditorInsertImagesButton;
TextEditor.MentionsMenu = TextEditorMentionsMenu;

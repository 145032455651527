import store from 'store';

import { constants } from '@nl-lms/common/shared';

type DropdownCatalogueFilter = {
  label: string;
  filter: any;
  children?: DropdownCatalogueFilter[];
};
type Features = {
  learnerSupportUrl?: string;
  defaultReportIncludeLearnerHistory: boolean;
  learnerCatalogueCourseDropdownFilters: DropdownCatalogueFilter[];
  learnerCatalogueElearningCourseDropdownFilters: DropdownCatalogueFilter[];
  learnerCatalogueLearningPathDropdownFilters: DropdownCatalogueFilter[];
  msTeamsIntegrationEnabled: boolean;
  gmeetIntegrationEnabled: boolean;
  publicRegistrationEnabled: boolean;
};

const getApiBaseFromHostname = () => {
  if (window.location.hostname === 'localhost') return 'http://localhost:3003';

  const match = /^(?:www\.)?([^.]+)/.exec(window.location.host);
  // @ts-ignore
  const envName = match[1];

  const urlBase = window.location.host.substring(
    window.location.host.indexOf('.') + 1,
    window.location.host.length
  );
  return `https://${envName}.api.${urlBase}`;
};

const EnvApiProps = {
  API_BASE: getApiBaseFromHostname(),
  API_ROOT: 'api',
  API_VERSION: 'v1',
};

export const C = {
  get NAME() {
    return store.get('NAME');
  },

  get LOCATION_DATA() {
    return store.get('LOCATION_DATA');
  },
  get LEARNER_PROPERTIES() {
    return store.get('LEARNER_PROPERTIES');
  },
  get I18N() {
    return store.get('I18N');
  },
  get LOGO_DETAILS() {
    return store.get('LOGO_DETAILS');
  },

  get VENUE_FEATURES() {
    return store.get('VENUE_FEATURES');
  },

  get VERSION() {
    return store.get('VERSION');
  },

  get IS_EXPERIMENTS_ENABLED() {
    // @ts-ignore
    return window.appConfig.IS_EXPERIMENTS_ENABLED;
  },

  get SSO() {
    return store.get('SSO');
  },

  // get NOTIFICATIONS(): {
  //   theme: NotificationTemplateTheme;
  //   receivers: ReceiverDataProperties;
  //   defaultLanguage: string;
  // } {
  //   return store.get('NOTIFICATIONS');
  // },

  get LEARNER_SYNC() {
    return store.get('LEARNER_SYNC');
  },

  get SENTRY_DSN() {
    // @ts-ignore
    return store.get('SENTRY_DSN') || window?.appConfig?.SENTRY_DSN || false;
  },

  get CUSTOMER_ID() {
    return store.get('CUSTOMER_ID');
  },

  get ENV() {
    return { ...EnvApiProps };
  },

  get CURRENCY() {
    return store.get('CURRENCY');
  },

  get DEFAULT_TIMEZONE() {
    try {
      return Intl.DateTimeFormat().resolvedOptions().timeZone;
    } catch (e) {
      return C.TIMEZONES[0];
    }
  },

  get UPLOAD_STRATEGY(): 'direct' | 'proxy' {
    return store.get('UPLOAD_STRATEGY');
  },

  get NOTIFICATIONS() {
    return store.get('NOTIFICATIONS') as {
      theme: { email: Record<string, unknown> };
      defaultLanguage: string;
      audiences: { name: string; label: string; fields: string[] }[];
    };
  },

  get FEATURES(): Features {
    return store.get('FEATURES');
  },
  get CONTENT_PLAYER_URL(): string {
    return store.get('CONTENT_PLAYER_URL');
  },

  CURRENCY_SYMBOLS: { EUR: '€', RON: 'RON', USD: '$' },

  DEFAULTS: constants.DEFAULTS,

  // regular values
  TIMEZONES: constants.TIMEZONES,
  LEARNER_STATUS: constants.LEARNER.STATUSES,
  LEARNER_RELATIONSHIP_TYPES: constants.LEARNER.RELATIONSHIP_TYPES,
  CURRENCIES: constants.CURRENCIES,
  LANGUAGES: constants.LANGUAGES,
  SCHEDULE_BLOCK: constants.COURSE.SCHEDULE_BLOCK,
  REGISTRATION_TYPES: constants.COURSE.REGISTRATION_TYPE,
  SESSION_STATUSES: constants.SESSION.STATUSES,
  SESSION_REQUIREMENTS: constants.SESSION.REQUIREMENTS,
  SESSION_LANGUAGES: constants.SESSION.LANGUAGES,
  SESSION_CANCELLATION_REASONS: constants.SESSION.CANCELLATION_REASONS,
  RESOURCE_STATUSES: constants.SESSION.RESOURCE.STATUSES,
  SURVEY_TYPES: constants.SURVEY.TYPES,
  COST_RESOURCE_TYPES: constants.COST_TYPE.RESOURCE_TYPES,
  APPROVAL_TYPES: constants.APPROVAL.TYPES,
  LEARNING_TYPES: constants.LEARNING_TYPES,
  TRAINING_SESSION_LEARNER_ASSIGNMENT_STATUSES:
    constants.TRAINING_SESSION_LEARNER.ASSIGNMENT_STATUSES,
  ELEARNING_SESSION_STATUSES: constants.ELEARNING_SESSION.STATUSES,
  LEARNING_ASSIGNMENT_TYPE: constants.LEARNING_ASSIGNMENT.TYPE,
  LEARNING_ASSIGNMENT_DUE_DATE_TYPES:
    constants.LEARNING_ASSIGNMENT.DUE_DATE_TYPES,
  LEARNING_ASSIGNMENT_INSTANCE_STATUS:
    constants.LEARNING_ASSIGNMENT.ITEM_INSTANCE_STATUS,
  LEARNING_ASSIGNMENT_INSTANCE_ITEM_TYPE:
    constants.LEARNING_ASSIGNMENT.ITEM_TYPE,
  ELEARNING_SESSION_RESULT_STATUSES:
    constants.ELEARNING_SESSION.RESULT_STATUSES,
  SURVEY_STATUSES: constants.SURVEY.STATUS,
  ASSESSMENT_INSTANCE_STATUSES: constants.ASSESSMENT.INSTANCE_STATUS,
  ASSESSMENT_INSTANCE_ATTEMPT_STATUSES:
    constants.ASSESSMENT.INSTANCE_ATTEMPT_STATUS,
  ASSESSMENT_FORM_QUESTION_ITEM_TYPES:
    constants.ASSESSMENT.FORM_QUESTION_ITEM_TYPE,
  ASSESSMENT_FORM_QUESTION_TYPE: constants.ASSESSMENT.QUESTION_TYPE,
  ASSESSMENT_VALIDATION_TYPE: constants.ASSESSMENT.VALIDATION_TYPE,
  CONTENT_FILE_VERSIONS: constants.CONTENT_FILE.VERSIONS,
  CONTENT_FILE_TYPES: constants.CONTENT_FILE.TYPES,
  LEARNING_PATH_INSTANCE_STATUSES: constants.LEARNING_PATH.INSTANCE_STATUS,
  LEARNING_PATH_ITEM_INSTANCE_STATUSES:
    constants.LEARNING_PATH.ITEM.INSTANCE_STATUS,
  LEARNER_LEARNING_TYPES: constants.LEARNER_LEARNING.TYPE,
  LEARNING_ACTIVITY_TYPES: constants.LEARNING_ACTIVITY.TYPES,
  INDIVIDUAL_LEARNING_TYPES: constants.INDIVIDUAL_LEARNING.TYPES,
  INDIVIDUAL_LEARNING_DELIVERY_TYPES:
    constants.INDIVIDUAL_LEARNING.DELIVERY_TYPE,
  CHECKLIST_INSTANCE_STATUSES: constants.CHECKLIST.INSTANCE_STATUS,
  VIRTUAL_LOCATION_TYPES: constants.VIRTUAL_LOCATION_TYPES,

  // inverted values
  I_LEARNER_STATUS: constants.I.LEARNER.STATUSES,
  I_CURRENCIES: constants.I.CURRENCIES,
  I_LANGUAGES: constants.I.LANGUAGES,
  I_SURVEY_TYPES: constants.I.SURVEY.TYPES,
  I_SURVEY_STATUS: constants.I.SURVEY.STATUS,
  I_SCHEDULE_BLOCK: constants.I.COURSE.SCHEDULE_BLOCK,
  I_REGISTRATION_TYPES: constants.I.COURSE.REGISTRATION_TYPE,
  I_SESSION_STATUSES: constants.I.SESSION.STATUSES,
  I_SESSION_CANCELLATION_REASONS: constants.I.SESSION.CANCELLATION_REASONS,
  I_ELEARNING_SESSION_STATUSES: constants.I.ELEARNING_SESSION.STATUSES,
  I_ELEARNING_SESSION_RESULT_STATUSES:
    constants.I.ELEARNING_SESSION.RESULT_STATUSES,
  I_TRAINING_SESSION_LEARNER_ASSIGNMENT_STATUSES:
    constants.I.TRAINING_SESSION_LEARNER.ASSIGNMENT_STATUSES,
  I_RESOURCE_STATUSES: constants.I.SESSION.RESOURCE.STATUSES,
  I_LEARNING_ASSIGNMENT_TYPE: constants.I.LEARNING_ASSIGNMENT.TYPE,
  I_LEARNING_ASSIGNMENT_DUE_DATE_TYPES:
    constants.I.LEARNING_ASSIGNMENT.DUE_DATE_TYPES,
  I_APPROVAL_TYPES: constants.I.APPROVAL.TYPES,
  I_LEARNING_TYPES: constants.I.LEARNING_TYPES,
  I_VIRTUAL_LOCATION_TYPES: constants.I.VIRTUAL_LOCATION_TYPES,
  I_ASSESSMENT_INSTANCE_STATUSES: constants.I.ASSESSMENT.INSTANCE_STATUS,
  I_ASSESSMENT_INSTANCE_ATTEMPT_STATUSES:
    constants.I.ASSESSMENT.INSTANCE_ATTEMPT_STATUS,
  I_ASSESSMENT_FORM_QUESTION_ITEM_TYPES:
    constants.I.ASSESSMENT.FORM_QUESTION_ITEM_TYPE,
  I_ASSESSMENT_VALIDATION_TYPE: constants.I.ASSESSMENT.VALIDATION_TYPE,
  I_ASSESSMENT_FORM_QUESTION_TYPE: constants.I.ASSESSMENT.QUESTION_TYPE,
  I_CONTENT_FILE_TYPES: constants.I.CONTENT_FILE.TYPES,
  I_CONTENT_FILE_VERSIONS: constants.I.CONTENT_FILE.VERSIONS,
  I_LEARNING_PATH_INSTANCE_STATUSES: constants.I.LEARNING_PATH.INSTANCE_STATUS,
  I_LEARNING_PATH_ITEM_INSTANCE_STATUSES:
    constants.I.LEARNING_PATH.ITEM.INSTANCE_STATUS,
  I_LEARNER_LEARNING_TYPES: constants.I.LEARNER_LEARNING.TYPE,
  I_LEARNING_PATH_ITEM_VALIDATION_TYPE:
    constants.I.LEARNING_PATH.ITEM.VALIDATION_TYPE,
  I_LEARNING_ACTIVITY_TYPES: constants.I.LEARNING_ACTIVITY.TYPES,
  I_INDIVIDUAL_LEARNING_TYPES: constants.I.INDIVIDUAL_LEARNING.TYPES,
  I_INDIVIDUAL_LEARNING_DELIVERY_TYPES:
    constants.I.INDIVIDUAL_LEARNING.DELIVERY_TYPE,
  I_CHECKLIST_INSTANCE_STATUSES: constants.I.CHECKLIST.INSTANCE_STATUS,
};

import React from 'react';

import { FieldMetadata, FieldType } from '@nl-lms/common/shared';
import { LearningProgramInstanceStatus } from '@nl-lms/feature/learning-programs/sdk';
import { ReportDataColumn } from '@nl-lms/feature/reports/sdk';
import { Sensitive } from '@nl-lms/ui/components';
import { C } from '@nl-lms/ui/constants';
import { FilterBar } from '@nl-lms/ui/modules';
import { formatConstantString } from '@nl-lms/ui/utils';
import { _ } from '@nl-lms/vendor';

import * as fetchAndMapFunctions from '../../../_common/utils/fetchEntitiesForSelectMethods';

export const AdminAnalyticsReportFormFilterBar = ({
  onChange,
  value,
  columns,
}) => {
  return (
    <FilterBar
      id="data-export-filters"
      onChangeFilters={onChange}
      initialFilters={value}
      fields={getFilterBarFieldsFromReportColumns(columns || [])}
    />
  );
};

const parseFieldLabel = (label: string) => {
  if (label === 'Learner Customer Internal Id' || label === 'Id') return label;
  return label.endsWith('Id') ? label.slice(0, -3) : label;
};
const getFilterBarFieldsFromReportColumns = (
  columns: ReportDataColumn[]
): FieldMetadata[] => {
  // @ts-ignore
  return [
    ...columns
      .map((col) => {
        if (!col.isFilterable) return;

        if (
          [
            FieldType.string,
            FieldType.boolean,
            FieldType.date,
            FieldType.datetime,
            FieldType.number,
          ].includes(col.type)
        ) {
          const rawName = col.name.toLowerCase();
          const isSensitive =
            rawName.includes('learner') ||
            rawName.includes('first_name') ||
            rawName.includes('last_name') ||
            rawName.includes('full_name') ||
            rawName.includes('email') ||
            rawName.includes('phone') ||
            rawName.includes('address') ||
            rawName.includes('manager') ||
            rawName.includes('internal') ||
            rawName.includes('building');

          let ValueComponent;
          if (isSensitive) {
            ValueComponent = ({ children }) => {
              return <Sensitive>{children}</Sensitive>;
            };
          }

          return {
            name: col.name,
            label: parseFieldLabel(col.label),
            type: col.type,
            ValueComponent,
          } as FieldMetadata;
        }

        if (
          [
            FieldType.select,
            FieldType.jsonArraySelect,
            FieldType.arraySelect,
          ].includes(col.type) &&
          col.options
        ) {
          let loadOptions;
          let ValueComponent;

          if ('selectOptionsName' in col.options) {
            loadOptions = async () =>
              // @ts-ignore
              SelectFieldsOptionsMap[col.options.selectOptionsName] || [];
          } else if ('loadOptions' in col.options) {
            loadOptions = fetchAndMapFunctions[col.options.loadOptions];
            const isSensitive = [
              'fetchAndMapLearners',
              'fetchAndMapManagers',
              'fetchAndMapTrainers',
              'fetchAndMapUsers',
            ].includes(col.options.loadOptions);
            if (isSensitive) {
              ValueComponent = ({ children }) => {
                return <Sensitive>{children}</Sensitive>;
              };
            }
          } else {
            return;
          }

          return {
            name: col.name,
            label: parseFieldLabel(col.label),
            type: col.type,
            loadOptions,
            ValueComponent,
          } as FieldMetadata;
        }
      })
      .filter((field) => !!field),
  ];
};

const SelectFieldsOptionsMap = {
  assessmentStatus: Object.keys(C.ASSESSMENT_INSTANCE_STATUSES).map(
    (status) => ({
      value: status,
      label: formatConstantString(C.ASSESSMENT_INSTANCE_STATUSES[status]),
    })
  ),
  elearningSessionStatus: Object.keys(C.ELEARNING_SESSION_STATUSES).map(
    (status) => ({
      value: status,
      label: formatConstantString(C.ELEARNING_SESSION_STATUSES[status]),
    })
  ),
  learningProgramInstanceStatus: fetchAndMapFunctions.mapAndLoadEnumsForSelect(
    LearningProgramInstanceStatus
  )(''),
  indiviualLearningType: Object.keys(C.INDIVIDUAL_LEARNING_TYPES).map(
    (type) => ({
      value: parseInt(type),
      label: formatConstantString(C.INDIVIDUAL_LEARNING_TYPES[type]),
    })
  ),
  deliveryTypes: Object.keys(C.INDIVIDUAL_LEARNING_DELIVERY_TYPES).map(
    (type) => ({
      value: parseInt(type),
      label: formatConstantString(C.INDIVIDUAL_LEARNING_DELIVERY_TYPES[type]),
    })
  ),
  learningPathStatus: Object.keys(C.LEARNING_PATH_INSTANCE_STATUSES).map(
    (status) => ({
      value: status,
      label: formatConstantString(C.LEARNING_PATH_INSTANCE_STATUSES[status]),
    })
  ),
  trainingSessionLearnerStatus: Object.keys(
    C.TRAINING_SESSION_LEARNER_ASSIGNMENT_STATUSES
  ).map((status) => ({
    value: status,
    label: formatConstantString(
      C.TRAINING_SESSION_LEARNER_ASSIGNMENT_STATUSES[status]
    ),
  })),
  languages: Object.keys(C.LANGUAGES).map((status) => ({
    value: status,
    label: formatConstantString(C.LANGUAGES[status]),
  })),
  registrationTypes: Object.keys(C.REGISTRATION_TYPES).map((status) => ({
    value: status,
    label: formatConstantString(C.REGISTRATION_TYPES[status]),
  })),
  trainingSessionStatus: Object.keys(C.SESSION_STATUSES).map((status) => ({
    value: status,
    label: formatConstantString(C.SESSION_STATUSES[status]),
  })),
  surveyTypes: Object.keys(C.SURVEY_TYPES).map((status) => ({
    value: status,
    label: formatConstantString(C.SURVEY_TYPES[status]),
  })),
  learningTypes: Object.keys(C.LEARNING_TYPES).map((status) => ({
    value: status,
    label: formatConstantString(C.LEARNING_TYPES[status]),
  })),
  learningUnitTypes: Object.keys(C.LEARNER_LEARNING_TYPES).map((status) => ({
    value: status,
    label: formatConstantString(C.LEARNER_LEARNING_TYPES[status]),
  })),
  learningUnitContentTypes: [
    ...Object.keys(C.LEARNING_TYPES).map((status) => ({
      value: `${C.I_LEARNER_LEARNING_TYPES.LIVE_COURSE}|${status}`,
      label: formatConstantString(C.LEARNING_TYPES[status]),
    })),
    ...Object.keys(C.INDIVIDUAL_LEARNING_TYPES).map((status) => ({
      value: `${C.I_LEARNER_LEARNING_TYPES.INDIVIDUAL}|${status}`,
      label: formatConstantString(C.INDIVIDUAL_LEARNING_TYPES[status]),
    })),
    ...Object.keys(C.CONTENT_FILE_TYPES).map((status) => ({
      value: `${C.I_LEARNER_LEARNING_TYPES.ELEARNING}|${status}`,
      label: formatConstantString(C.CONTENT_FILE_TYPES[status]),
    })),
  ],
  allLearningTypes: Object.keys(
    _.omit(
      {
        ...C.I_LEARNER_LEARNING_TYPES,
        LIVE_SESSION: 7,
      },
      'LIVE_COURSE'
    )
  ).map((type) => ({
    value: type,
    label: formatConstantString(type),
  })),
  individualLearningTypes: Object.keys(C.INDIVIDUAL_LEARNING_TYPES).map(
    (status) => ({
      value: status,
      label: formatConstantString(C.LEARNING_TYPES[status]),
    })
  ),
  surveyStatus: Object.keys(C.SURVEY_STATUSES).map((status) => ({
    value: status,
    label: formatConstantString(C.SURVEY_STATUSES[status]),
  })),
};

import { useAbility } from '@casl/react';
import _ from 'lodash';
import React, { useMemo } from 'react';

import {
  AppQueryFilter,
  FieldType,
  QueryFilterCombinator,
  QueryFilterOperatorType,
  QueryOperator,
} from '@nl-lms/common/shared';
import {
  Card,
  Link,
  NoDataPlaceholder,
  PrettyDate,
  StatusTag,
  Table,
} from '@nl-lms/ui/components';
import { C } from '@nl-lms/ui/constants';
import {
  FilterBar,
  ListViewProvider,
  useListViewContext,
} from '@nl-lms/ui/modules';
import { formatConstantString } from '@nl-lms/ui/utils';
import { AbilityContext } from '@nl-lms/web/Can';
import { authStore } from '@nl-lms/web/_common/modules/Auth/auth';
import { adminApi } from '@nl-lms/web/_common/services/api';

import { routes } from '../../../../../lib/routes';
import {
  mapAndLoadConstantsForSelect,
  mapAndLoadValArrayForSelect,
} from '../../../../_common/utils/fetchEntitiesForSelectMethods';
import './AdminLearnerLearningHistoryCard.scss';

const learningHistoryStatuses = [
  C.LEARNER_STATUS[C.I_LEARNER_STATUS.DECLINED],
  C.LEARNER_STATUS[C.I_LEARNER_STATUS.ATTENDED],
  C.LEARNER_STATUS[C.I_LEARNER_STATUS.ABSENT],
  C.LEARNER_STATUS[C.I_LEARNER_STATUS.GO_SHOW],

  C.ELEARNING_SESSION_STATUSES[C.I_ELEARNING_SESSION_STATUSES.COMPLETED],
  C.ELEARNING_SESSION_STATUSES[C.I_ELEARNING_SESSION_STATUSES.FAILED],
  C.ELEARNING_SESSION_STATUSES[C.I_ELEARNING_SESSION_STATUSES.COMPLETED_FAILED],
  C.ELEARNING_SESSION_STATUSES[C.I_ELEARNING_SESSION_STATUSES.COMPLETED_PASSED],

  C.ASSESSMENT_INSTANCE_STATUSES[C.I_ASSESSMENT_INSTANCE_STATUSES.PASSED],
  C.ASSESSMENT_INSTANCE_STATUSES[C.I_ASSESSMENT_INSTANCE_STATUSES.FAILED],

  C.SURVEY_STATUSES[C.I_SURVEY_STATUS.COMPLETED],

  C.LEARNING_PATH_INSTANCE_STATUSES[
    C.I_LEARNING_PATH_INSTANCE_STATUSES.COMPLETED
  ],
  C.LEARNING_PATH_INSTANCE_STATUSES[C.I_LEARNING_PATH_INSTANCE_STATUSES.FAILED],

  C.CHECKLIST_INSTANCE_STATUSES[C.I_CHECKLIST_INSTANCE_STATUSES.COMPLETED],
  C.CHECKLIST_INSTANCE_STATUSES[C.I_CHECKLIST_INSTANCE_STATUSES.FAILED],
];

export const AdminLearnerLearningHistoryCard = ({ learnerId }) => {
  const { hasAdminRole } = authStore;
  const baseFilters = useMemo<AppQueryFilter>(
    () => ({
      id: 'learner-learning-history-filters',
      value: [
        // exclude assessments for managers
        {
          id: 'learner-learning-history-type',
          value: {
            field: { label: 'learningType', field: 'learningType' },
            operator: {
              label: 'Includes',
              value: QueryOperator.Includes,
              type: QueryFilterOperatorType.Unary,
            },
            value: {
              label: 'learningTypeValue',
              value: hasAdminRole ? ['1', '2', '3', '4', '5', '6'] : ['2'],
            },
          },
        },
      ],
    }),
    []
  );

  const initialFilters = useMemo<AppQueryFilter>(
    () => ({
      id: 'learner-learning-history-filters',
      value: [
        // exclude assessments for managers
        {
          id: 'learner-learning-history-status',
          value: {
            field: { label: 'status', field: 'status' },
            operator: {
              label: 'Includes',
              value: QueryOperator.Includes,
              type: QueryFilterOperatorType.Unary,
            },
            value: {
              label: 'learningStatus',
              value: learningHistoryStatuses,
            },
            combinator: QueryFilterCombinator.And,
          },
        },
      ],
    }),
    []
  );

  return (
    <ListViewProvider
      key="list-learner-learning-history"
      name="list-learner-learning-history"
      paginationLimit={10}
      initialSorting={{ field: 'createdAt', order: 'desc' }}
      initialFilters={initialFilters}
      baseFilters={baseFilters}
      useUrlQuery={false}
      persistToLocalStorage={false}
    >
      <AdminLearnerLearningHistoryTable learnerId={learnerId} />
    </ListViewProvider>
  );
};

const AdminLearnerLearningHistoryTable = ({ learnerId }) => {
  const { pagination, sorting, onChangePagination, onChangeSorting, query } =
    useListViewContext();
  const { useListLearnerLearningActivityQuery } = adminApi;
  const { data, isLoading } = useListLearnerLearningActivityQuery({
    query,
    learnerId,
  });

  const rows = data?.rows || [];
  const rowCount = data?.count || 0;
  const tablePagination = useMemo(
    () => ({
      ...pagination,
      pageSize: pagination.limit,
      rowCount,
    }),
    [rowCount, pagination]
  );

  const learningUnitRoute = {
    [C.I_LEARNING_ACTIVITY_TYPES.ASSESSMENT_INSTANCE]:
      routes.admin.catalogue.assessments.item.path.full,
    [C.I_LEARNING_ACTIVITY_TYPES.ELEARNING_SESSION]:
      routes.admin.catalogue.elearningCourses.item.path.full,
    [C.I_LEARNING_ACTIVITY_TYPES.LEARNING_PATH_INSTANCE]:
      routes.admin.catalogue.learningPaths.item.path.full,
    [C.I_LEARNING_ACTIVITY_TYPES.TRAINING_SESSION]:
      routes.admin.catalogue.liveCourses.item.path.full,
    [C.I_LEARNING_ACTIVITY_TYPES.SURVEY_INSTANCE]:
      routes.admin.catalogue.surveys.item.path.full,
    [C.I_LEARNING_ACTIVITY_TYPES.CHECKLIST_INSTANCE]:
      routes.admin.catalogue.checklists.item.path.full,
  };

  const learningUnitPermissions = {
    [C.I_LEARNING_ACTIVITY_TYPES.ASSESSMENT_INSTANCE]:
      'assessment_instance_page',
    [C.I_LEARNING_ACTIVITY_TYPES.ELEARNING_SESSION]: 'elearning_course_page',
    [C.I_LEARNING_ACTIVITY_TYPES.LEARNING_PATH_INSTANCE]: 'learning_path_page',
    [C.I_LEARNING_ACTIVITY_TYPES.TRAINING_SESSION]: 'live_session',
    [C.I_LEARNING_ACTIVITY_TYPES.SURVEY_INSTANCE]: 'survey_page',
    [C.I_LEARNING_ACTIVITY_TYPES.CHECKLIST_INSTANCE]: 'checklist_page',
  };

  const ability = useAbility(AbilityContext);

  return (
    <Card>
      <Card.Header separatorMarginBottom={0}>
        <Card.HeaderTitle>Learning History</Card.HeaderTitle>
        <div className="admin-learner-learning-history-card__filters">
          <AdminLearnerLearningHistoryCardTableFilters learnerId={learnerId} />
        </div>
      </Card.Header>
      <Card.Content
        paddingType="none"
        className="admin-learner-learning-history-card__table"
      >
        <Table
          data={rows}
          isLoading={isLoading}
          pagination={tablePagination}
          onChangePagination={onChangePagination}
          sorting={sorting}
          onChangeSorting={onChangeSorting}
          columns={[
            {
              Header: 'Learning Unit',
              accessor: 'name',
              sortField: 'name',
              Cell: ({ row }) =>
                ability.can(
                  'view',
                  learningUnitPermissions[row.original.learningType]
                ) ? (
                  <Link
                    to={learningUnitRoute[row.original.learningType](
                      row.original?.parentId
                    )}
                  >
                    {row?.original?.name}
                  </Link>
                ) : (
                  <span>{row?.original?.name}</span>
                ),
            },
            {
              Header: 'Learning Type',
              accessor: 'learningType',
              sortField: 'learningType',
              Cell: ({ row }) =>
                formatConstantString(
                  C.LEARNING_ACTIVITY_TYPES[row.original?.learningType]
                ),
            },
            {
              Header: 'Status',
              accessor: 'status',
              sortField: 'status',
              Cell: ({ row }) => <StatusTag status={row.original?.status} />,
            },
            {
              Header: 'Score',
              accessor: 'score',
              sortField: 'score',
              Cell: ({ row }) => row?.original.score || '-',
            },
            {
              Header: 'Mandatory',
              accessor: 'mandatory',
              sortField: 'mandatory',
              Cell: ({ row }) => (row?.original?.mandatory ? 'Yes' : 'No'),
            },
            {
              Header: 'Start Date',
              accessor: 'startDate',
              sortField: 'startDate',
              Cell: ({ row }) => (
                <PrettyDate value={row?.original?.startDate} withTime />
              ),
            },
            {
              Header: 'End Date',
              accessor: 'endDate',
              sortField: 'endDate',
              Cell: ({ row }) => (
                <PrettyDate value={row?.original?.endDate} withTime />
              ),
            },
            {
              Header: 'Due Date',
              accessor: 'dueDate',
              sortField: 'dueDate',
              Cell: ({ row }) => (
                <PrettyDate value={row?.original?.dueDate} withTime />
              ),
            },
          ]}
        >
          <NoDataPlaceholder
            title="There is no learning history for this learner"
            iconName="ListIcon"
          />
        </Table>
      </Card.Content>
    </Card>
  );
};

export const AdminLearnerLearningHistoryCardTableFilters = ({ learnerId }) => {
  const { filters, onChangeFilters } = useListViewContext();
  // const { hasAdminRole } = authStore;
  const statuses = _.uniq([
    ...Object.values(C.LEARNER_STATUS),
    ...Object.values(C.ELEARNING_SESSION_STATUSES),
    ...Object.values(C.ASSESSMENT_INSTANCE_STATUSES),
    ...Object.values(C.LEARNING_PATH_INSTANCE_STATUSES),
    ...Object.values(C.SURVEY_STATUSES),
    ...Object.values(C.CHECKLIST_INSTANCE_STATUSES),
  ]);
  return (
    <FilterBar
      id="learning-history"
      fields={[
        {
          name: 'name',
          label: 'Learning Unit Name',
          type: FieldType.string,
        },
        {
          name: 'learning_type',
          label: 'Learning Type',
          type: FieldType.select,
          loadOptions: mapAndLoadConstantsForSelect(C.LEARNING_ACTIVITY_TYPES),
        },
        {
          name: 'status',
          label: 'Status',
          type: FieldType.select,
          loadOptions: mapAndLoadValArrayForSelect(statuses),
        },
        {
          name: 'score',
          label: 'Score',
          type: FieldType.number,
        },
        {
          name: 'mandatory',
          label: 'Mandatory',
          type: FieldType.boolean,
        },
        {
          name: 'start_date',
          label: 'Start Date',
          type: FieldType.datetime,
        },
        {
          name: 'end_date',
          label: 'End Date',
          type: FieldType.datetime,
        },
      ]}
      initialFilters={filters}
      onChangeFilters={onChangeFilters}
    />
  );
};

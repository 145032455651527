import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { transformTsRestQuery } from '@nl-lms/sdk/backend';
import {
  ErrorPlaceholder,
  NoDataPlaceholder,
  NoDataPlaceholderColor,
  Pagination,
} from '@nl-lms/ui/components';
import { useWindowSize } from '@nl-lms/ui/hooks';
import {
  ListViewProvider,
  useListViewContext,
  useListViewTableData,
} from '@nl-lms/ui/modules';
import { authStore } from '@nl-lms/web/_common/modules/Auth/auth';
import { contentLibraryItemsApi } from '@nl-lms/web/_common/services/api';

import {
  LearnerAppLayoutTileGrid,
  LearnerAppLayoutTileGridItem,
  LearnerAppLayoutTileGridNoData,
} from '../../_common/components/LearnerAppMainLayout/LearnerAppMainLayout';
import { LearnerLibraryListCard } from '../../_common/components/LearnerLibraryListCard/LearnerLibraryListCard';
import { LearnerLearningTile } from '../LearnerLearningTile/LearnerLearningTile';
import { learnerLibraryByCompetenciesFilter } from './LearnerLibraryFilters';
import './LearnerLibraryList.scss';

const { useListContentLibraryItemsQuery } = contentLibraryItemsApi;
export const LearnerLibraryList = ({ filters }) => {
  const { query, onChangeFilters } = useListViewContext();

  const competencyFilter = useMemo(
    () => learnerLibraryByCompetenciesFilter(filters),
    [filters, learnerLibraryByCompetenciesFilter]
  );

  useEffect(() => {
    onChangeFilters?.(competencyFilter);
  }, [competencyFilter]);

  const { data, isLoading, isError, isFetching, isSuccess } =
    useListContentLibraryItemsQuery({
      query: {
        query: transformTsRestQuery(query),
      },
    });

  // @ts-ignore
  const [contentLibraryItems] = useListViewTableData(data);
  const { t } = useTranslation('learner');

  if (isLoading || isFetching) {
    return (
      <LearnerAppLayoutTileGrid>
        <LearnerAppLayoutTileGridItem>
          <LearnerLearningTile.Skeleton />
        </LearnerAppLayoutTileGridItem>
        <LearnerAppLayoutTileGridItem>
          <LearnerLearningTile.Skeleton />
        </LearnerAppLayoutTileGridItem>
        <LearnerAppLayoutTileGridItem>
          <LearnerLearningTile.Skeleton />
        </LearnerAppLayoutTileGridItem>
      </LearnerAppLayoutTileGrid>
    );
  }

  if (isSuccess && !contentLibraryItems.length) {
    return (
      <>
        <LearnerAppLayoutTileGridNoData>
          <NoDataPlaceholder
            iconName="ImageIcon"
            title={
              filters
                ? t('opportunities.contentlibraryfilterplaceholder.title')
                : t('opportunities.contentlibraryplaceholder.title')
            }
            subtitle={
              filters
                ? t('opportunities.contentlibraryfilterplaceholder.subtitle')
                : t('opportunities.contentlibraryplaceholder.subtitle')
            }
            color={
              filters
                ? NoDataPlaceholderColor.warning
                : NoDataPlaceholderColor.default
            }
          />
        </LearnerAppLayoutTileGridNoData>
      </>
    );
  }

  if (isError) {
    return (
      <LearnerAppLayoutTileGridNoData>
        <ErrorPlaceholder>
          <ErrorPlaceholder.Image />
          {/*// @ts-ignore */}
          <ErrorPlaceholder.Title>
            {/*// @ts-ignore */}
            {t('dashboard.error.title')}
          </ErrorPlaceholder.Title>
          <ErrorPlaceholder.Description>
            {t('dashboard.error.description')}
          </ErrorPlaceholder.Description>
        </ErrorPlaceholder>
      </LearnerAppLayoutTileGridNoData>
    );
  }

  return (
    <LearnerAppLayoutTileGrid>
      {[...contentLibraryItems].map((libItem) => (
        <LearnerAppLayoutTileGridItem key={libItem?.id}>
          <LearnerLibraryListCard id={libItem?.id} />
        </LearnerAppLayoutTileGridItem>
      ))}
    </LearnerAppLayoutTileGrid>
  );
};

export const LearnerLibraryListPagination = () => {
  const { pagination, onChangePagination, query, filters } =
    useListViewContext();

  const { data } = useListContentLibraryItemsQuery({
    query: {
      query: transformTsRestQuery({
        ...query,
        filters,
      }),
    },
  });

  const rowCount = data?.count || 0;

  const { width } = useWindowSize();
  useEffect(() => {
    if ((width ?? 0) > 1200 && (width ?? 0) < 1800) {
      onChangePagination({ ...pagination, limit: 4 });
    } else if ((width ?? 0) > 1800 && (width ?? 0) < 2100) {
      onChangePagination({ ...pagination, limit: 5 });
    } else if ((width ?? 0) > 2100) {
      onChangePagination({ ...pagination, limit: 6 });
    }
  }, [width]);

  return (
    <Pagination
      className="learner-library-list__pagination"
      pagination={{ ...pagination, rowCount }}
      onChangePage={onChangePagination}
    />
  );
};

export const LearnerLibraryListContext = ({ children }) => {
  return (
    <ListViewProvider
      key="learner-content-library-items"
      name="learner-content-library-items"
      paginationLimit={4}
      initialSorting={{ field: 'title', order: 'asc' }}
      persistToLocalStorage={false}
      useUrlQuery={false}
    >
      {children}
    </ListViewProvider>
  );
};

LearnerLibraryList.ListContext = LearnerLibraryListContext;
LearnerLibraryList.Pagination = LearnerLibraryListPagination;

export default LearnerLibraryList;

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { SurveyInstance } from '@nl-lms/sdk/backend';
import {
  Badge,
  Icon,
  InlineDotList,
  PrettyDate,
  StatusTag,
  Table,
  TableWithoutRowSelectionMenu,
  Typography,
} from '@nl-lms/ui/components';
import { C } from '@nl-lms/ui/constants';
import { useGetUrlQuery } from '@nl-lms/ui/hooks';
import { _ } from '@nl-lms/vendor';

import { CardIconCaption, LearningBanner } from '../../../_common/components';
import { authStore } from '../../../_common/modules/Auth/auth';
import { learnerApi, useApi } from '../../../_common/services/api';
import { routes } from '../../../lib/routes';
import LearnerAppMainLayout from '../../_common/components/LearnerAppMainLayout/LearnerAppMainLayout';
import {
  CardBodySectionAlignment,
  LearnerLearningUnitPageLayout,
} from '../../_common/components/LearnerLearningUnitPageLayout/LearnerLearningUnitPageLayout';
import { LearnerSurveyButton } from '../../_common/components/LearnerSurveyButton';

const { useGetSurveyFormWithInstanceQuery } = learnerApi;

const selectedInstancesStatusMap = {
  [C.I_SURVEY_STATUS.NOT_STARTED]: 7,
  [C.I_SURVEY_STATUS.IN_PROGRESS]: 6,
};

export const LearnerSurveyViewPage = () => {
  const { id } = useParams<{ id: string }>() as { id: string };
  const learnerId = authStore.learnerId as string;
  const api = useApi();
  const query = useGetUrlQuery();
  const { t } = useTranslation('learner');

  const [initialDataLoaded, setInitialDataLoaded] = useState(false);
  const [selectedInstance, setSelectedInstance] =
    useState<SurveyInstance | null>(null);
  const [assignedActiveInstances, setAssignedActiveInstances] = useState([]);
  const [regularInstances, setRegularInstances] = useState([]);
  const [regularInProgressInstances, setRegularInProgressInstances] = useState(
    []
  );

  const { data, isLoading } = useGetSurveyFormWithInstanceQuery(
    {
      id,
      learnerId,
    },
    {
      refetchOnFocus: true,
    }
  );

  const getInstance = useCallback(
    async (surveyInstanceId) => {
      try {
        const instance = await api.learnerApp.getSurveyFormInstance({
          learnerId: authStore.learnerId,
          id: surveyInstanceId,
        });
        setSelectedInstance(instance);
      } catch (e) {
        console.error(e);
      }
    },
    [api, setSelectedInstance]
  );

  useEffect(() => {
    setSelectedInstance(null);
    setInitialDataLoaded(false);
    setAssignedActiveInstances([]);
    setRegularInstances([]);
    setRegularInProgressInstances([]);
  }, [id]);

  const onSelectRow = useCallback(
    (row) => {
      const hasInProgressInstances = !!regularInProgressInstances.length;
      if (typeof row === 'boolean') {
        if (row) return;
        else setSelectedInstance(null);
      } else if (row?.id === selectedInstance?.id && !hasInProgressInstances) {
        setSelectedInstance(null);
      } else {
        setSelectedInstance(row);
      }
    },
    [selectedInstance, regularInProgressInstances]
  );

  const onViewRow = useCallback((row) => {
    window.open(routes.portal.survey.item.path.full(row.id));
  }, []);

  useEffect(() => {
    if (!data?.surveyFormInstances) return;

    let _activeInstances = [];
    const _regularInstances = [];
    (data?.surveyFormInstances || []).forEach((l) => {
      if (
        //@ts-ignore
        l.learningAssignmentInstanceId &&
        l.status !== C.I_SURVEY_STATUS.COMPLETED &&
        l.status !== C.I_SURVEY_STATUS.CANCELED
      ) {
        // @ts-ignore
        _activeInstances.push(l);
      } else {
        // @ts-ignore
        _regularInstances.push(l);
      }
    });

    // @ts-ignore
    _activeInstances = _.sortBy(
      _activeInstances.map((es) => {
        return {
          // @ts-ignore
          ...es,
          // @ts-ignore
          i: selectedInstancesStatusMap[es.status],
          // @ts-ignore
          m: es.mandatory ? 1 : 0,
        };
      }),
      ['m', 'i', 'due_date'],
      ['desc', 'desc', 'desc']
    );

    const _regularInProgressInstances = _regularInstances
      // @ts-ignore
      .filter((s) => s.status === C.I_SURVEY_STATUS.IN_PROGRESS)
      .sort(
        (a, b) =>
          // @ts-ignore
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      );
    setAssignedActiveInstances(_activeInstances);
    setRegularInstances(_regularInstances);
    setRegularInProgressInstances(_regularInProgressInstances);

    let surveyInstance;
    if (query.instanceId) {
      surveyInstance = (data?.surveyFormInstances || []).find(
        (a) => a.id === query.instanceId
      );
      if (!surveyInstance) {
        getInstance(query.instanceId);
      }
    } else if (_activeInstances && _activeInstances.length) {
      surveyInstance = _activeInstances[0];
    } else if (
      _regularInProgressInstances &&
      _regularInProgressInstances.length
    ) {
      surveyInstance = _regularInProgressInstances[0];
    }

    if (!initialDataLoaded) setInitialDataLoaded(true);
    if (surveyInstance) setSelectedInstance(surveyInstance);
  }, [data]);

  const activeAssignedSelectedInstances = useMemo(() => {
    // @ts-ignore
    if (!selectedInstance?.id) {
      return assignedActiveInstances && assignedActiveInstances[0]
        ? [assignedActiveInstances[0]]
        : [];
    } else {
      const s = assignedActiveInstances.find(
        // @ts-ignore
        (s) => selectedInstance.id === s.id
      );
      return s ? [s] : [];
    }
  }, [assignedActiveInstances, selectedInstance]);

  const regularSelectedInstances = useMemo(() => {
    if (assignedActiveInstances && assignedActiveInstances.length) {
      return [];
    }

    // @ts-ignore
    if (selectedInstance?.id) {
      // @ts-ignore
      const s = regularInstances.find((s) => selectedInstance.id === s.id);
      return s ? [s] : [];
    }

    if (assignedActiveInstances && assignedActiveInstances.length) {
      return [];
    }

    const [latestInProgressInstance] = regularInProgressInstances;
    return latestInProgressInstance ? [latestInProgressInstance] : [];
  }, [
    assignedActiveInstances,
    assignedActiveInstances,
    regularInstances,
    regularInProgressInstances,
    selectedInstance,
  ]);

  const canSelectRegularInstance = useMemo(() => {
    if (!assignedActiveInstances || !assignedActiveInstances.length)
      return true;
  }, [assignedActiveInstances]);

  return (
    <LearnerAppMainLayout>
      <LearnerLearningUnitPageLayout isLoading={isLoading}>
        <LearnerLearningUnitPageLayout.HeroImage
          imageUrl={`"${data?.surveyForm.thumbnail}"`}
        />

        {selectedInstance &&
          // @ts-ignore
          selectedInstance.status !== C.I_SURVEY_STATUS.COMPLETED && (
            <LearningBanner
              // @ts-ignore
              dueDate={selectedInstance.dueDate}
              // @ts-ignore
              mandatory={selectedInstance.mandatory}
              className={''}
            />
          )}

        <LearnerLearningUnitPageLayout.CardHeaderTitle>
          {data?.surveyForm.name}
        </LearnerLearningUnitPageLayout.CardHeaderTitle>

        <LearnerLearningUnitPageLayout.CardHeaderItem>
          <InlineDotList>
            <CardIconCaption
              icon={<Icon.ClipboardIconSmall />}
              caption={t('learningunit.survey')}
            />
          </InlineDotList>
        </LearnerLearningUnitPageLayout.CardHeaderItem>

        <LearnerLearningUnitPageLayout.CardBodySection>
          <LearnerLearningUnitPageLayout.CardBodySectionDescription>
            <span
              dangerouslySetInnerHTML={{
                // @ts-ignore
                __html: data?.surveyForm.description,
              }}
            />
          </LearnerLearningUnitPageLayout.CardBodySectionDescription>
        </LearnerLearningUnitPageLayout.CardBodySection>

        <LearnerLearningUnitPageLayout.CardBodySection
          // @ts-ignore
          title={t('learningunit.competencies')}
        >
          <Badge label={t('learningunit.nocompetencies')} />
        </LearnerLearningUnitPageLayout.CardBodySection>

        <LearnerLearningUnitPageLayout.CardHistory>
          {data?.surveyFormInstances?.length ? (
            <>
              {!!assignedActiveInstances.length && (
                <LearnerLearningUnitPageLayout.CardBodySection
                  // @ts-ignore
                  title={t('learningunit.activeassignments')}
                  align={CardBodySectionAlignment.LEFT}
                >
                  <Typography.p>
                    {t('learningunit.activeassignmentsdescription')}
                  </Typography.p>
                  <br />
                  <TableWithoutRowSelectionMenu
                    columns={[
                      {
                        Header: t('common.table.startedon'),
                        accessor: 'createdAt',
                        Cell: ({ row }) => {
                          return (
                            <PrettyDate
                              value={
                                row.original.startedOn || row.original.createdAt
                              }
                              withTime
                            />
                          );
                        },
                      },
                      {
                        Header: t('common.table.status'),
                        accessor: 'status',
                        sortField: 'status',
                        Cell: ({ row }) => {
                          return (
                            <StatusTag
                              status={C.SURVEY_STATUSES[row.original.status]}
                            />
                          );
                        },
                      },

                      {
                        Header: t('common.table.mandatory'),
                        accessor: 'mandatory',
                        Cell: ({ row }) =>
                          row.original.mandatory
                            ? t('common.statustag.yes')
                            : t('common.statustag.no'),
                      },
                      {
                        Header: t('common.table.duedate'),
                        accessor: 'dueDate',
                        Cell: ({ row }) => (
                          <PrettyDate value={row.original.dueDate} />
                        ),
                      },
                    ]}
                    selectedRows={activeAssignedSelectedInstances}
                    isLoading={!initialDataLoaded && isLoading}
                    selectionMode={'none'}
                    data={assignedActiveInstances}
                  />
                </LearnerLearningUnitPageLayout.CardBodySection>
              )}

              <LearnerLearningUnitPageLayout.CardBodySection
                // @ts-ignore
                title={t('learningunit.history')}
                align={CardBodySectionAlignment.LEFT}
              >
                <Typography.p>
                  {t('learningunit.historydescription', {
                    unit: t('learningunit.survey'),
                  })}
                </Typography.p>
                <Table
                  rowActions={[
                    {
                      name: 'View',
                      handler: onViewRow,
                      Icon: Icon.EyeIcon,
                    },
                  ]}
                  columns={[
                    {
                      Header: t('common.table.startedon'),
                      accessor: 'createdAt',
                      Cell: ({ row }) => {
                        return (
                          <PrettyDate
                            value={
                              row.original.startedOn || row.original.createdAt
                            }
                            withTime
                          />
                        );
                      },
                    },
                    {
                      Header: t('common.table.status'),
                      accessor: 'status',
                      sortField: 'status',
                      Cell: ({ row }) => {
                        return (
                          <StatusTag
                            status={C.SURVEY_STATUSES[row.original.status]}
                          />
                        );
                      },
                    },

                    {
                      Header: t('common.table.mandatory'),
                      accessor: 'mandatory',
                      Cell: ({ row }) =>
                        row.original.mandatory
                          ? t('common.statustag.yes')
                          : t('common.statustag.no'),
                    },
                    {
                      Header: t('common.table.duedate'),
                      accessor: 'dueDate',
                      Cell: ({ row }) => (
                        <PrettyDate value={row.original.dueDate} />
                      ),
                    },
                  ]}
                  selectedRows={regularSelectedInstances}
                  isLoading={!initialDataLoaded && isLoading}
                  selectionMode={canSelectRegularInstance ? 'radio' : 'none'}
                  // @ts-ignore
                  onSelectRow={canSelectRegularInstance ? onSelectRow : null}
                  data={regularInstances}
                />
              </LearnerLearningUnitPageLayout.CardBodySection>
            </>
          ) : (
            <>
              {t('learningunit.noactivity', {
                unit: t('learningunit.survey'),
              })}
            </>
          )}
        </LearnerLearningUnitPageLayout.CardHistory>

        <LearnerLearningUnitPageLayout.FooterAction>
          <LearnerSurveyButton
            instance={selectedInstance}
            surveyForm={data?.surveyForm}
          />
        </LearnerLearningUnitPageLayout.FooterAction>
      </LearnerLearningUnitPageLayout>
    </LearnerAppMainLayout>
  );
};

import _ from 'lodash';

import {
  LearningAssignment,
  LearningAssignmentLearningContentType,
} from '@nl-lms/common/feature/types';
import {
  LearningAssignmentAutomation,
  LearningAssignmentDomainEventNames,
} from '@nl-lms/feature/learning-assignments/sdk';
import {
  LearningProgramAutomation,
  LearningProgramDomainEventNames,
} from '@nl-lms/feature/learning-programs/sdk';
import {
  FlowGraphConnection,
  FlowGraphEdgeType,
  FlowGraphEdgeTypes,
  FlowGraphEntity,
  FlowGraphEntityColor,
  FlowGraphNodeType,
  FlowGraphNodeTypes,
} from '@nl-lms/ui/components';
import { formatConstantString } from '@nl-lms/ui/utils';

import { DefaultReferenceEntities } from '../learning-assignment-rule/utils/constants';
import { parseFormDataConditions } from '../learning-assignment-rule/utils/utils';
import {
  LearningProgramGraphEdgeColorMap,
  ProgramRuleTemplates,
  ProgramRulesTemplateTypes,
} from './constants';
import {
  END_NODE,
  ProgramRule,
  RULE_NODE,
  RuleCondition,
  START_NODE,
  UpsertProgramRulePayload,
} from './types';

type ProgramGraphData = {
  assignmentRules?: ProgramRule[] | [];
  assignments: (LearningAssignment & {
    content: {
      type: LearningAssignmentLearningContentType;
      id: string;
      name: string;
    };
  })[];
  isConnectable?: boolean;
  isHighlighted?: (nodeId: string) => boolean;
  completionPaths?: { 'End-Completed': string[][]; 'End-Failed': string[][] };
  startingAssignmentIds?: string[];
};

export const isProgramRule = (rule) =>
  rule?.entityName === LearningProgramAutomation.entityName;
export const isAssignmentRule = (rule) =>
  rule?.entityName === LearningAssignmentAutomation.entityName;

export const isProgramEndRule = (rule) =>
  isProgramRule(rule) &&
  [
    LearningProgramAutomation.actions.markInstanceAsCompleted,
    LearningProgramAutomation.actions.markInstanceAsFailed,
  ].includes(rule?.actionName);
export const isProgramCompletionRule = (rule) =>
  isProgramRule(rule) &&
  rule?.actionName ===
    LearningProgramAutomation.actions.markInstanceAsCompleted;
export const isProgramFailedRule = (rule) =>
  isProgramRule(rule) &&
  rule?.actionName === LearningProgramAutomation.actions.markInstanceAsFailed;

export const isProgramStartRule = (rule) =>
  rule?.conditions?.some(
    (cond) =>
      cond?.value?.eventName ===
      LearningProgramDomainEventNames.LearningProgramInstanceCreated
  ) && isAssignmentRule(rule);

const isSelfConnectingRule = (condition, rule) =>
  [
    LearningAssignmentDomainEventNames.LearningAssignmentInstanceFailed,
    LearningAssignmentDomainEventNames.LearningAssignmentInstanceCompleted,
  ].includes(condition?.value?.eventName) &&
  (rule?.entityId || rule?.entity?.id) === condition?.value?.referenceEntityId;

const isAutoAssignRule = (condition, rule) =>
  [LearningAssignmentDomainEventNames.LearnerJoinedAudience].includes(
    condition?.value?.eventName
  ) &&
  (rule?.entityId || rule?.entity?.id) === condition?.value?.referenceEntityId;

const isMandatoryForCompletion = (
  programCompletionPaths: {
    'End-Completed': string[][];
    'End-Failed': string[][];
  },
  assignmentId: string
) =>
  programCompletionPaths
    ? programCompletionPaths?.[FlowGraphNodeTypes.End.Completed]?.some?.(
        (path) => path?.includes(assignmentId)
      )
    : false;

const isSource = (id: string, connections: FlowGraphConnection[]) =>
  connections?.some((conn) => conn?.source === id);
const isTarget = (id: string, connections: FlowGraphConnection[]) =>
  connections?.some((conn) => conn?.target === id);
const getNodeColorsByConnections = (
  id: string,
  connections: FlowGraphConnection[]
) =>
  isTarget(id, connections)
    ? connections
        ?.filter((conn) => conn?.target === id)
        ?.map((conn) => conn?.edgeData?.color)
    : [];

const parseAssignmentsToEntities = (data, connections) => {
  const graphEntities: FlowGraphEntity[] = [];

  // START_NODE: NodeStart
  graphEntities.push({
    id: START_NODE,
    type: FlowGraphNodeTypes.Start as FlowGraphNodeType,
    nodeData: {
      isSource: !!data?.startingAssignmentIds?.length,
      isHighlighted: data?.isHighlighted,
      name: 'Program Start',
    },
  });

  if (!data?.assignmentRules?.some((rule) => isProgramCompletionRule(rule))) {
    // END_NODE: NodeEnd - users will find it easier to set completion rule
    graphEntities.push({
      id: `${END_NODE.COMPLETED}`,
      type: FlowGraphNodeTypes.End.Completed as FlowGraphNodeType,
      nodeData: {
        name: 'Program Completed',
        isHighlighted: data?.isHighlighted,
      },
    });
  }

  // ASSIGNMENT_NODES: NodeEntity
  data?.assignments?.map((assignment) => {
    graphEntities.push({
      id: assignment?.id,
      type: FlowGraphNodeTypes.Entity as FlowGraphNodeType,
      nodeData: {
        ...assignment,
        name: assignment?.name,
        info: formatConstantString(
          LearningAssignmentLearningContentType[assignment?.content?.type]
        ),
        headerText: isMandatoryForCompletion(
          data?.completionPaths,
          assignment?.id
        )
          ? 'Mandatory For Completion'
          : 'Optional For Completion',
        headerColor: isMandatoryForCompletion(
          data?.completionPaths,
          assignment?.id
        )
          ? FlowGraphEntityColor.Info
          : FlowGraphEntityColor.Default,
        isSource: isSource(assignment?.id, connections),
        isTarget: isTarget(assignment?.id, connections),
        colors: getNodeColorsByConnections(assignment?.id, connections),
        isHighlighted: data?.isHighlighted,
      },
    });
  });

  data?.assignmentRules?.map((rule, ruleIndex) => {
    // END RULE NODES: NodeEnd (Completed/Failed)
    if (isProgramCompletionRule(rule)) {
      graphEntities.push({
        id: `${END_NODE.COMPLETED}_${ruleIndex}`,
        type: FlowGraphNodeTypes.End.Completed as FlowGraphNodeType,
        nodeData: {
          name: 'Program Completed',
          isHighlighted: data?.isHighlighted,
        },
      });
    } else if (isProgramFailedRule(rule)) {
      graphEntities.push({
        id: `${END_NODE.FAILED}_${ruleIndex}`,
        type: FlowGraphNodeTypes.End.Failed as FlowGraphNodeType,
        nodeData: {
          name: 'Program Failed',
          isHighlighted: data?.isHighlighted,
        },
      });
    }

    // ADVANCED RULE NODES: NodeConnection (>1 conditions)
    if (rule?.conditions?.length > 1) {
      graphEntities.push({
        id: `${RULE_NODE}_${ruleIndex}`,
        type: FlowGraphNodeTypes.Connection as FlowGraphNodeType,
        nodeData: {
          ...rule,
          index: ruleIndex,
          name: isProgramEndRule(rule)
            ? isProgramCompletionRule(rule)
              ? 'Assignments Completed'
              : 'Assignments Failed'
            : rule.name,
          isHighlighted: data?.isHighlighted,
          color: LearningProgramGraphEdgeColorMap.Default,
        },
      });
    }
  });

  return graphEntities;
};

const parseRulesToConnections = (data) => {
  const rules = data?.assignmentRules;
  const assignments = data?.assignments;
  const graphConnections: FlowGraphConnection[] = [];

  rules?.map((rule, ruleIndex) => {
    if (!rule?.conditions?.length) return [];
    const target = rule?.entityId || rule?.entity?.id;
    if (isProgramStartRule(rule)) {
      const startConnection = {
        id: `${ruleIndex}-${START_NODE}-${rule?.entityId}-${rule?.id}`,
        source: START_NODE,
        target,
        sourceHandle: '',
        targetHandle: '',
        type: FlowGraphEdgeTypes.Default as FlowGraphEdgeType,
        edgeData: {
          ...rule,
          name: rule?.name,
          type: FlowGraphEdgeTypes.Default,
          color:
            LearningProgramGraphEdgeColorMap[
              LearningAssignmentDomainEventNames.LearnerJoinedAudience
            ],
          index: 0,
          parentData: {
            index: ruleIndex,
            id: rule?.id,
          },
          isHighlighted: data?.isHighlighted,
        },
      };
      graphConnections.push(startConnection);
    }

    // ADVANCED_RULES: DefaultEdge
    else if (rule?.conditions?.length > 1) {
      const targetConnectionTarget = isProgramEndRule(rule)
        ? `${
            isProgramCompletionRule(rule) ? END_NODE.COMPLETED : END_NODE.FAILED
          }_${ruleIndex}`
        : target;

      // DefaultEdge: From NodeConnection (advanced rule node) to the target node (assignment / end node)
      const targetConnection = {
        id: `${ruleIndex}-${RULE_NODE}-${ruleIndex}-${targetConnectionTarget}`,
        source: `${RULE_NODE}_${ruleIndex}`,
        target: targetConnectionTarget,
        sourceHandle: '',
        targetHandle: '',
        type: FlowGraphEdgeTypes.Default as FlowGraphEdgeType,
        edgeData: {
          ...rule,
          name: rule?.name,
          type: FlowGraphEdgeTypes.Default,
          color: LearningProgramGraphEdgeColorMap.Default,
          parentData: {
            index: ruleIndex,
            id: rule?.id,
          },
          isHighlighted: data?.isHighlighted,
        },
      };
      graphConnections.push(targetConnection);
      // DefaultEdge: From source assignments to the NodeConnection (advanced rules)
      const sourceConnections = rule?.conditions?.map((cond, condIndex) => {
        const source =
          cond?.value?.referenceEntityId || cond?.value?.referenceEntity?.id;
        const condition = {
          value: cond?.value,
          combinator:
            cond?.combinator ||
            rule?.conditionsEquation?.value?.[condIndex]?.combinator ||
            null,
        };
        return {
          id: `${ruleIndex}-${condIndex}-${source}-${RULE_NODE}-${ruleIndex}`,
          source,
          target: `${RULE_NODE}_${ruleIndex}`,
          sourceHandle: '',
          targetHandle: '',
          // @ts-ignore
          type: FlowGraphEdgeTypes.Default,
          edgeData: {
            ...rule,
            name: rule.name,
            type: FlowGraphEdgeTypes.Default,
            color: LearningProgramGraphEdgeColorMap.Default,
            parentData: {
              index: ruleIndex,
              id: rule?.id,
            },
            isHighlighted: data?.isHighlighted,
            condition,
          },
        };
      });
      sourceConnections.map((sc) => graphConnections.push(sc));
    } else {
      // ConnectorEdge: From source assignmen to target assignment / end
      rule?.conditions?.map((cond, condIndex) => {
        if (isAutoAssignRule(cond, rule)) {
          return;
        }
        const source = isAutoAssignRule(cond, rule)
          ? START_NODE
          : (cond?.value?.referenceEntityId ||
              cond?.value?.referenceEntity?.id) ===
            DefaultReferenceEntities.assignmentId.value
          ? target
          : cond?.value?.referenceEntityId || cond?.value?.referenceEntity?.id;
        const assignmentName =
          assignments?.find((a) => a?.id === rule?.entityId)?.name || '';
        const sourceHandle = isSelfConnectingRule(cond, rule)
          ? `${assignmentName}-right`
          : '';
        const targetHandle = isSelfConnectingRule(cond, rule)
          ? `${assignmentName}-left`
          : '';
        const type = isSelfConnectingRule(cond, rule)
          ? FlowGraphEdgeTypes.Self
          : FlowGraphEdgeTypes.Connector;

        const selfIndex = isSelfConnectingRule(cond, rule)
          ? graphConnections?.filter(
              (flatRule) =>
                (rule?.entityId || rule?.entity?.id) ===
                  (flatRule?.edgeData?.entityId ||
                    flatRule?.edgeData?.entity?.id) &&
                isSelfConnectingRule(
                  flatRule?.edgeData?.condition,
                  flatRule?.edgeData
                )
            )?.length
          : 0;

        const targetEnd = `${
          isProgramCompletionRule(rule) ? END_NODE.COMPLETED : END_NODE.FAILED
        }_${ruleIndex}`;

        const condition = {
          value: cond?.value,
          combinator:
            cond?.combinator ||
            rule?.conditionsEquation?.value?.[condIndex]?.combinator ||
            null,
        };

        graphConnections.push({
          id: `${ruleIndex}-${condIndex}-${target}-${source}`,
          source,
          target: isProgramEndRule(rule) ? targetEnd : target,
          sourceHandle,
          targetHandle,
          // @ts-ignore
          type,
          edgeData: {
            ...rule,
            condition,
            name: rule?.name,
            type,
            index: condIndex,
            selfIndex,
            color:
              LearningProgramGraphEdgeColorMap?.[cond?.value?.eventName] ||
              '#ddd',
            parentData: {
              index: ruleIndex,
              id: rule?.id,
            },
            isHighlighted: data?.isHighlighted,
          },
        });
      });
    }
  });

  return graphConnections;
};

export const parseProgramDataToFlowGraph = (data: ProgramGraphData) => ({
  entities: parseAssignmentsToEntities(data, parseRulesToConnections(data)),
  connections: parseRulesToConnections(data),
});

const rulePayloadProgramConditions = (ruleData) => {
  if (ruleData?.conditions?.length) {
    const parsedConditions = parseFormDataConditions(
      ruleData?.conditions,
      ruleData?.entity,
      'learningProgram'
    );
    if (ruleData?.conditions?.length === 1) {
      return parsedConditions[0]?.value;
    }

    return parsedConditions;
  }
  return {};
};

export const parseFormRulesToProgramsPayload = (ruleData) => ({
  ...ruleData,
  scheduleDate: ruleData?.scheduleDate?.value ? ruleData?.scheduleDate : null,
  settings: {
    triggerNTimes: ruleData?.settings?.triggerNTimes
      ? parseInt(ruleData?.settings?.triggerNTimes)
      : null,
  },
  conditionsEquation: {
    value: rulePayloadProgramConditions(ruleData),
  },
});

const removeHandlePosition = (handleId) =>
  handleId
    ?.replace('-top', '')
    ?.replace('-right', '')
    ?.replace('-bottom', '')
    ?.replace('-left', '');

export const buildLearningProgramGraphNodesRuleByTemplate = (params: {
  template:
    | ProgramRulesTemplateTypes.assignCompleted
    | ProgramRulesTemplateTypes.recurrenceFailed
    | ProgramRulesTemplateTypes.programCompleted
    | ProgramRulesTemplateTypes.programFailed
    | ProgramRulesTemplateTypes.programCreated;
  target: string;
  targetHandle: string;
  source: string;
  sourceHandle: string;
}) => ({
  entities: {
    source: {
      id: params?.source,
      name: removeHandlePosition(params?.sourceHandle),
    },
    target: {
      id: params?.target,
      name: removeHandlePosition(params?.targetHandle),
    },
  },
  template: params?.template,
  name:
    ProgramRuleTemplates?.find((templ) => templ?.value === params?.template)
      ?.payload?.name || 'No rule',
});

const replaceLearningProgramRule = ({
  template,
  name,
  entities: { target },
  rules,
  editingRuleData,
  combinator = null,
  scheduleDate,
}: UpsertProgramRulePayload) => {
  const eligibleRuleIndex = editingRuleData?.index as number;
  const RuleTemplate = ProgramRuleTemplates?.find(
    (ruleTemplate) => ruleTemplate?.value === template
  );

  if (eligibleRuleIndex > -1) {
    const replaceConditionIndex =
      editingRuleData && !_.isNaN(editingRuleData?.conditionIndex)
        ? editingRuleData?.conditionIndex ?? -1
        : -1;

    let newRuleConditionsCopy = [
      ...(editingRuleData?.conditions ||
        rules[eligibleRuleIndex]?.conditions ||
        []),
    ];

    const hasCombinator =
      replaceConditionIndex > 0 ||
      (replaceConditionIndex === -1 && newRuleConditionsCopy?.length > 0);

    const newRuleCondition = {
      value: {
        ...RuleTemplate?.payload?.conditions?.value,
        referenceEntity: {
          id: target?.id,
          name: target?.name,
        },
        referenceEntityId: target?.id,
      },
      combinator: hasCombinator ? combinator ?? 'and' : null,
    };

    if (replaceConditionIndex > -1) {
      newRuleConditionsCopy?.splice(replaceConditionIndex, 1, newRuleCondition);
    } else {
      newRuleConditionsCopy = [...newRuleConditionsCopy, newRuleCondition];
    }

    return {
      rule: {
        ...(rules?.[eligibleRuleIndex] as ProgramRule),
        name,
        scheduleDate,
        conditions: newRuleConditionsCopy,
      },
      replaceIndex: eligibleRuleIndex,
    };
  }

  return {};
};

export const addLearningProgramRule = ({
  programId,
  template,
  name,
  entities,
  rules,
  scheduleDate,
}: UpsertProgramRulePayload) => {
  const RuleTemplate = ProgramRuleTemplates?.find(
    (ruleTemplate) => ruleTemplate?.value === template
  );
  const ruleSource = entities?.source;
  const ruletTarget = entities?.target;

  // we're not going to build advanced rules with self-connecting rules
  const isSelfRule = ruleSource?.id === ruletTarget?.id;
  const isStartRule = template === ProgramRulesTemplateTypes.programCreated;

  const ruleEntityName = [
    ProgramRulesTemplateTypes.programCompleted,
    ProgramRulesTemplateTypes.programFailed,
  ].includes(template)
    ? LearningProgramAutomation.entityName
    : LearningAssignmentAutomation.entityName;

  let eligibleRuleIndex = -1;

  if (
    isProgramRule({ entityName: ruleEntityName }) &&
    ![END_NODE.COMPLETED, END_NODE.FAILED].includes(ruleSource?.id)
  ) {
    eligibleRuleIndex =
      parseInt?.(
        ruleSource?.id
          ?.replace(`${END_NODE.COMPLETED}_`, '')
          ?.replace(`${END_NODE.FAILED}_`, '')
      ) ?? -1;
  } else if (!isSelfRule && !isStartRule && rules?.length) {
    eligibleRuleIndex = rules?.findIndex(
      (rule: ProgramRule) =>
        // check if there is already an existing rule applied to the subject assignment
        rule?.entityId === ruleSource?.id &&
        !rule?.conditions?.some(
          (cond: RuleCondition) =>
            // check whether it's self connecting rule, program related rule or starting rule
            isSelfConnectingRule(cond, rule) || isProgramStartRule(rule)
        )
    );
  }

  if (eligibleRuleIndex > -1) {
    return replaceLearningProgramRule({
      programId,
      template,
      name,
      entities,
      rules,
      editingRuleData: { index: eligibleRuleIndex },
      combinator: 'and',
      scheduleDate,
    });
  }

  const ruleEntity =
    ruleEntityName === LearningProgramAutomation.entityName
      ? { id: programId, name: 'Learning Program' }
      : ruleSource;

  return {
    rule: {
      ...RuleTemplate?.payload,
      active: true,
      name,
      scheduleDate,
      entity: ruleEntity,
      entityId: ruleEntity.id,
      settings: { triggerNTimes: '1' },
      conditions: [
        {
          value: {
            ...RuleTemplate?.payload?.conditions?.value,
            referenceEntity: {
              id: ruletTarget?.id,
              name: isSelfRule
                ? DefaultReferenceEntities.assignmentId.label
                : ruletTarget?.name,
            },
            referenceEntityId: ruletTarget?.id,
          },
          combinator: null,
        },
      ],
    },
    replaceIndex: eligibleRuleIndex,
  };
};

export const buildOrReplaceLearningProgramRule = (
  ruleParams: UpsertProgramRulePayload
) =>
  ruleParams?.editingRuleData &&
  ruleParams?.editingRuleData?.index !== undefined &&
  ruleParams?.editingRuleData?.conditionIndex !== undefined
    ? replaceLearningProgramRule(ruleParams)
    : addLearningProgramRule(ruleParams);

export const parseRulePayloadToProgramComponent = (rule, assignmentName = '') =>
  ({
    ...rule,
    entity: {
      id: rule?.entityId,
      name: rule?.entity?.name || assignmentName,
    },
    conditions: (rule?.conditions as ProgramRule['conditions'])?.map(
      (cond, index) => {
        if ('value' in cond) {
          return cond;
        }
        const combinator =
          cond?.combinator ||
          rule?.conditionsEquation?.[index]?.combinator ||
          null;
        return {
          value: cond,
          combinator,
        };
      }
    ),
    settings:
      rule?.settings && rule?.settings?.triggerNTimes
        ? { triggerNTimes: `${rule?.settings?.triggerNTimes}` }
        : rule?.settings,
  } as ProgramRule);

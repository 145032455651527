import { z } from 'zod';

export enum ContentLibraryItemType {
  Article = 1,
  Document = 2,
  Media = 3,
  Book = 4,
  'Web Page' = 5,
}

export const ContentLibraryItemSchema = z.object({
  id: z.string(),
  name: z.string(),
  url: z.string(),
  type: z.nativeEnum(ContentLibraryItemType),
  description: z.string().nullable(),
  thumbnail: z.string().nullable(),
  tagIds: z.array(z.string()),
  competencyIds: z.array(z.string()),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type ContentLibraryItem = z.infer<typeof ContentLibraryItemSchema>;

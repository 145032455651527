import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Competency } from '@nl-lms/common/feature/types';
import {
  ContentLibraryItem,
  ContentLibraryItemType,
} from '@nl-lms/feature/content-library/sdk';
import { Button, Icon } from '@nl-lms/ui/components';

import { contentLibraryItemsApi } from '../../../../_common/services/api';
import { LearnerLearningTile } from '../../../modules/LearnerLearningTile/LearnerLearningTile';

type LearnerLibraryListCardProps = {
  id: string;
};

const { useGetContentLibraryItemQuery } = contentLibraryItemsApi;

export const LearnerLibraryListCard = ({ id }: LearnerLibraryListCardProps) => {
  const { t } = useTranslation('learner');
  const { data } = useGetContentLibraryItemQuery({
    contentLibraryItemId: id,
  });
  const item = data as any as ContentLibraryItem & {
    competencies: Competency[];
  };
  const itemTypeInfo = useMemo(() => {
    let icon = <></>;
    let type = 'default';
    let detail = '';
    if (item?.type === ContentLibraryItemType.Article) {
      icon = <Icon.FileIcon />;
      type = 'info';
      detail = t('learningunit.article');
    } else if (item?.type === ContentLibraryItemType.Document) {
      icon = <Icon.FolderIcon />;
      type = 'info';
      detail = t('learningunit.document');
    } else if (item?.type === ContentLibraryItemType.Media) {
      icon = <Icon.ImageIcon />;
      type = 'success';
      detail = t('learningunit.media');
    } else if (item?.type === ContentLibraryItemType.Book) {
      icon = <Icon.BookIcon />;
      type = 'alert';
      detail = t('learningunit.book');
    } else if (item?.type === ContentLibraryItemType['Web Page']) {
      icon = <Icon.MonitorIcon />;
      type = 'warning';
      detail = t('learningunit.webpage');
    }
    return { icon, type, detail };
  }, [item]);

  const onOpenClick = () => {
    window.open(item?.url, '_blank', 'noopener');
  };

  const libraryItemAction = (type: ContentLibraryItemType) => {
    switch (type) {
      case ContentLibraryItemType.Article:
      case ContentLibraryItemType.Document:
        return t('common.learningactivitycard.read');
      case ContentLibraryItemType.Media:
        return t('common.learningactivitycard.view');
      case ContentLibraryItemType.Book:
        return t('common.learningactivitycard.borrow');
      case ContentLibraryItemType['Web Page']:
        return t('common.learningactivitycard.access');
      default:
        return '';
    }
  };

  const actions = useMemo(
    () => [
      <Button
        regular
        onClick={onOpenClick}
        label={libraryItemAction(item?.type)}
        small
      />,
    ],
    [item]
  );

  return (
    <LearnerLearningTile>
      <LearnerLearningTile.Thumbnail imageUrl={item?.thumbnail} />
      <LearnerLearningTile.Title>{item?.name}</LearnerLearningTile.Title>
      <LearnerLearningTile.TitleIcon type={itemTypeInfo.type}>
        {itemTypeInfo.icon}
      </LearnerLearningTile.TitleIcon>
      <LearnerLearningTile.Detail>
        {itemTypeInfo.detail}
      </LearnerLearningTile.Detail>
      <LearnerLearningTile.Description>
        {item?.description}
      </LearnerLearningTile.Description>
      <LearnerLearningTile.Tags>
        {item?.competencies && item?.competencies.length
          ? item?.competencies?.map((tag) => tag.title)
          : [t('learningunit.nocompetencies')]}
      </LearnerLearningTile.Tags>
      {actions.map((action, index) => (
        <LearnerLearningTile.Action key={index}>
          {action}
        </LearnerLearningTile.Action>
      ))}
    </LearnerLearningTile>
  );
};

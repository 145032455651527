import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { Vendor } from '@nl-lms/sdk/backend';
import {
  Box,
  Button,
  FormField,
  Input,
  Sensitive,
  Separator,
  SideModal,
  Typography,
  useModal,
} from '@nl-lms/ui/components';
import { getMessageFromError } from '@nl-lms/ui/utils';

import { useSubmitUpsertEntityFromSideModal } from '../../../_common/hooks/useSubmitUpsertEntityFromSideModal';
import { LocationSelect } from '../../_common/LocationSelect';

type Props = {
  vendor?: Partial<Vendor>;
};

const Schema = yup.object().shape({
  name: yup.string().required(),
  companyName: yup.string().required(),
  companyEmail: yup.string().email().required(),
  companyPhoneNo: yup.string(),
  location: yup.object(),
  address: yup.string(),
  touchpointName: yup.string().required(),
  touchpointPhoneNo: yup.string(),
  touchpointEmail: yup.string().email().required(),
});

export const AdminVendorEditFormSideModal = ({ vendor = {} }: Props) => {
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(Schema),
    mode: 'onSubmit',
    defaultValues: {
      ...vendor,
      location: {
        city: vendor.city,
        region: vendor.region,
        country: vendor.country,
      },
    },
  });
  const { onSubmit, isLoading, error } = useSubmitUpsertEntityFromSideModal({
    updateHookName: 'useUpdateVendorMutation',
    createHookName: 'useCreateVendorMutation',
    entityId: vendor.id,
    parseEntity: (payload) => ({ ...payload, ...payload?.location }),
  });

  return (
    <SideModal.Content>
      <SideModal.Header>
        <SideModal.Title>
          {vendor.id ? 'Edit Vendor' : 'Create New Vendor'}
        </SideModal.Title>
      </SideModal.Header>
      <SideModal.Body>
        <Box margin={{ bottom: 'm' }}>
          <Typography.h2>Vendor Details</Typography.h2>
        </Box>
        <Box>
          <Box>
            <FormField
              label="Alias"
              helpText="The name that we will use throughout the entire app in various forms, reports and views"
              helpTextOffset={60}
              required
              errorMessage={errors?.name?.message}
            >
              <Input
                required
                {...register('name')}
                hasError={!!errors?.name?.message}
              />
            </FormField>
          </Box>
        </Box>
        <Box>
          <Box>
            <FormField
              required
              label="Company Name"
              helpText="The actual name of the legal entity"
              helpTextOffset={30}
              errorMessage={errors?.companyName?.message}
            >
              <Input
                {...register('companyName')}
                required
                hasError={!!errors?.companyName?.message}
              />
            </FormField>
          </Box>
          <Box>
            <FormField
              required
              label="Company Email"
              errorMessage={errors?.companyEmail?.message}
            >
              <Sensitive>
                <Input
                  {...register('companyEmail')}
                  required
                  hasError={!!errors?.companyEmail?.message}
                />
              </Sensitive>
            </FormField>
          </Box>
        </Box>
        <Box>
          <Box>
            <FormField
              label="Company Phone Number"
              errorMessage={errors?.companyPhoneNo?.message}
            >
              <Sensitive>
                <Input
                  {...register('companyPhoneNo')}
                  hasError={!!errors?.companyPhoneNo?.message}
                />
              </Sensitive>
            </FormField>
          </Box>
          <Box>
            <FormField label="Address" errorMessage={errors?.address?.message}>
              <Sensitive>
                <Input
                  {...register('address')}
                  required
                  hasError={!!errors?.address?.message}
                />
              </Sensitive>
            </FormField>
          </Box>
        </Box>
        <Box>
          <Box>
            <FormField label="Location" required>
              <Controller
                control={control}
                name="location"
                render={({ field }) => (
                  <Sensitive>
                    <LocationSelect
                      {...field}
                      initialLocation={{
                        // @ts-ignore
                        country: vendor.country,
                        // @ts-ignore
                        city: vendor.city,
                        // @ts-ignore
                        region: vendor.region,
                      }}
                    />
                  </Sensitive>
                )}
              />
            </FormField>
          </Box>
        </Box>
        <Separator />
        <Box margin={{ bottom: 'm' }}>
          <Typography.h2>Touchpoint</Typography.h2>
        </Box>
        <Box>
          <Box>
            <FormField
              label="Touchpoint Name"
              required
              errorMessage={errors?.touchpointName?.message}
            >
              <Sensitive>
                <Input
                  {...register('touchpointName')}
                  required
                  hasError={!!errors?.touchpointName?.message}
                />
              </Sensitive>
            </FormField>
          </Box>
          <Box>
            <FormField label="Touchpoint Phone Number">
              <Sensitive>
                <Input
                  {...register('touchpointPhoneNo')}
                  hasError={!!errors?.touchpointPhoneNo?.message}
                />
              </Sensitive>
            </FormField>
          </Box>
        </Box>
        <Box>
          <Box>
            <FormField
              label="Touchpoint Email"
              required
              errorMessage={errors?.touchpointEmail?.message}
            >
              <Sensitive>
                <Input
                  type="email"
                  {...register('touchpointEmail')}
                  required
                  hasError={!!errors?.touchpointEmail?.message}
                />
              </Sensitive>
            </FormField>
          </Box>
        </Box>
      </SideModal.Body>

      <SideModal.Actions>
        <SideModal.Error>{getMessageFromError(error)}</SideModal.Error>
        <Button
          label={vendor.id ? 'Save' : 'Create'}
          onClick={handleSubmit(onSubmit)}
          isLoading={isLoading}
        />
      </SideModal.Actions>
    </SideModal.Content>
  );
};
